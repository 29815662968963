import {StateManager} from '../stateManager/StateManager';
import {ActionType} from '../stateManager/ActionTypes'

import {TaskCell} from './TaskCell';
import { Utils } from '../util/Utils';

TaskCell.toString();

export class TaskStoreState {
    /**@type {TaskCell[]} */ tasks = [];
    /**@type {string} */ lastUpdated = '';
}

/** @type {TaskStoreState} */
let initialState = new TaskStoreState();


/**
 * @typedef {Object} Action 
 * @property {string} type
 * @property {string} [taskId]
 * @property {TaskCell} [cell]
 * @property {TaskCell[]} [cells]
 */

  /**
  * 
  * @param {TaskCell} cell 
  * @return {Action}
  */
 const upsertAction = (cell) => {
    return {
        type: ActionType.UPSERT,
        cell
    }
 }

/**
* 
* @param {string} taskId 
* @return {Action}
*/
const deleteAction = (taskId) => {
    return {
        type: ActionType.DELETE,
        taskId,
    }
}

 
  /**
  * 
  * @param {TaskCell[]} cells 
  * @return {Action}
  */
 const insertAllAction = (cells) => {
    return {
        type: ActionType.INSERT_ALL,
        cells, 
    }
 }

 ///////////////// reducer

 /** 
  * @param {Action} action 
  * @return {TaskStoreState}
  */
export let dataReducer = (state = initialState, action) => {
    try {
        switch (action.type) {

            case ActionType.UPSERT:
                return {
                    tasks: upsert(state.tasks, action.cell),
                    lastUpdated: new Date().getTime().toString(),
                }
            case ActionType.DELETE:
                return {
                    tasks: remove(state.tasks, action.taskId),
                    lastUpdated: new Date().getTime().toString(),
                }
            case ActionType.INSERT_ALL:
                return {
                    tasks: action.cells,    // return all the cells to replace the state
                    lastUpdated: new Date().getTime().toString(),
                }
            default:
                return state;
        }
            
    } catch (error) {
        Utils.error('Error in dataReducer', error.message);
        throw new Error('Error in updating task state!'); // send user-oriented error message
    }
}

/**
 * @param {TaskCell[]} state - contains the original cells
 * @param {TaskCell} cell
 * @return {TaskCell[]}
 */
const upsert = (state, cell) => {
    /**@type {TaskCell[]} */
    const allCells = JSON.parse(JSON.stringify(state)) // deep clone 
    const idx = allCells.findIndex( (c) => {
        return cell.taskId === c.taskId
    })
    if (idx >= 0) {  
        allCells.splice(idx,1, cell); // delete and insert
    } else {
        allCells.push(cell);  // insert
    }
    return allCells;
}

/**
 * @param {TaskCell[]} state - contains the original cells
 * @param {string} taskId
 * @return {TaskCell[]}
 */
const remove = (state, taskId) => {
    /**@type {TaskCell[]} */
    const allCells = JSON.parse(JSON.stringify(state)) // deep clone 
    const idx = allCells.findIndex( (c) => {
        return taskId === c.taskId
    })
    if (idx >= 0) {  
        allCells.splice(idx,1); // delete
    } else {
        Utils.error(`Error deleting cell in state! ${taskId} not found!`);
    }
    return allCells;
}
/////////////////////////// helper public functions

/**
 * @param {TaskCell} cell
 */
export const upsertCell = (cell) => {
    StateManager.store.dispatch(upsertAction(cell));
}

/**
 * @param {string} taskId
 */
export const deleteCell = (taskId) => {
    StateManager.store.dispatch(deleteAction(taskId));
}

/**
 * @param {TaskCell[]} cells
 */
export const insertAll = (cells) => {
    StateManager.store.dispatch(insertAllAction(cells));
}