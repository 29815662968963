/** 
 * Class names of icons from sprite 
 * 
 */

//  white
export const HOME_WHITE                 = 'icons8-home-white-20';
export const SEARCH_WHITE               = 'icons8-search-white-20';
export const ADD_WHITE                  = 'icons8-plus-white-20';
export const BACK_WHITE                 = 'icons8-back-white-20';
export const SAVE_WHITE                 = 'icons8-save-white-24';
export const SAVE_NEW_WHITE             = 'icons8-save-new-white-24';
export const DELETE_WHITE               = 'icons8-delete-white-24';
export const EXPAND_WHITE               = 'icons8-expand-white-16';
export const COLLAPSE_WHITE             = 'icons8-collapse-white-16';
export const PAGE_HORIZONTAL_WHITE      = 'icons8-horizontal-paging-white-20';
export const PAGE_VERTICAL_WHITE        = 'icons8-vertical-paging-white-20';
export const SHOW_COMPLETED_WHITE       = 'icons8-tick-box-white-20';
export const HIDE_COMPLETED_WHITE       = 'icons8-tick-box-selected-white-20';
export const FILTER_WHITE               = 'icons8-filter-white-20';
export const FILTER_SELECTED_WHITE      = 'icons8-filter-selected-white-20';
export const COLLAPSE_ALL_WHITE         = 'icons8-double-up-white-20';
export const EXPAND_ALL_WHITE           = 'icons8-double-down-white-20';
export const SETTINGS_WHITE             = 'icons8-settings-white-20';
export const OPTIONS_WHITE              = 'icons8-menu-vertical-white-20';
export const SWITCH_VIEWS_WHITE         = 'icons8-switch-views-white-20';

export const SHORTCUT_MENU              = 'icons8-shortcut-menu-32';
export const SHORTCUT_MENU_HOME         = 'icons8-shortcut-menu-home-32';
export const SHORTCUT_MENU_ADD          = 'icons8-shortcut-menu-add-32';
export const SHORTCUT_MENU_SEARCH       = 'icons8-shortcut-menu-search-32';
export const SHORTCUT_MENU_SETTINGS     = 'icons8-shortcut-menu-settings-20';

// header
export const MAIN_MENU                  = 'icons8-top-menu-24';
export const SEARCH                     = 'icons8-search-24';

// footer
export const BACK                       = 'icons8-left-24';
export const NEW_TASK                   = 'icons8-plus-math-24';
export const FILTER                     = 'icons8-filter-24';
export const EXPAND_ALL                 = 'icons8-double-up-24';
export const COLLAPSE_ALL               = 'icons8-double-down-24';
export const PAGE_HORIZONTAL            = 'icons8-left-right-24';
export const PAGE_VERTICAL              = 'icons8-up-down-24';
export const SHOW_COMPLETED             = 'icons8-check-all-24';
export const HIDE_COMPLETED             = 'icons8-check-all-filled-24';

// 
export const SAVE                       = 'icons8-done-24';
export const CANCEL                     = 'icons8-u-turn-to-left-24';

// black
export const ADD                    = 'icons8-plus-20';
export const EXPAND                 = 'icons8-expand-arrow-24';
export const COLLAPSE               = 'icons8-collapse-arrow-24';
export const INFO                   = 'icons8-info-20';
export const CALENDAR               = 'icons8-calendar-20';
export const CROSS                  = 'icons8-cross-20';
export const CHECKED_BOX            = 'icons8-checked-checkbox-24';
export const UNCHECKED_BOX          = 'icons8-unchecked-checkbox-24';
export const LIST                   = 'icons8-list-20';
export const DROPDOWN               = 'icons8-dropdown-20';
export const NEXT                   = 'icons8-next-20';
export const PREVIOUS               = 'icons8-prev-20';

// home images
export const HOME_NEW               = 'icons8-home-create-40';
export const HOME_SEARCH            = 'icons8-home-search-40';
export const HOME_VIEW              = 'icons8-home-view-40';
export const HOME_APPS              = 'icons8-thumbnails-40';
export const HOME_SETTINGS          = 'icons8-home-setup-40';
export const HOME_INFO              = 'icons8-home-info-40';

export const CAROUSEL_TASKS         = 'icons8-test-passed-128';
export const CAROUSEL_CATEGORIES    = 'icons8-thumbnails-128';
export const CAROUSEL_SEARCH        = 'icons8-view-128';
export const CAROUSEL_SCHEDULE      = 'icons8-timetable-128';
export const CAROUSEL_SORT          = 'icons8-sorting-128';

// menu images
export const MENU_BY_CATEGORY       = 'icons8-hierarchy-24';
export const MENU_BY_HOT_TASKS      = 'icons8-fire-24';
export const MENU_BY_DATE           = 'icons8-calendar-24';
export const MENU_BY_PRIORITY       = 'icons8-high-priority-24';
export const MENU_BY_IMPORTANCE     = 'icons8-box-important-24';
export const MENU_BY_STATUS         = 'icons8-ok-24';
export const MENU_BY_LABEL          = 'icons8-price-tag-24';


// error images
export const ERROR_PENCIL           = 'icons8-broken-pencil-48';
export const ERROR_EXPLOSION        = 'icons8-explosion-48';
export const ERROR_CAR              = 'icons8-crashed-car-48';
export const ERROR_ROBOT            = 'icons8-broken-robot-48';

