import React from 'react';
import * as TaskManager from '../../data/TaskManager'
import * as CategoryManager from '../../data/CategoryManager'

import { ByDateNavbar  } from './ByDateNavBar';
import { ByDateView } from './ByDateView'

import { goToPage, goBack } from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import { TaskCell } from '../../data/TaskCell';
import { log, isEmpty, logWarn, Utils } from '../../util/Utils';
import { StateManager } from '../../stateManager/StateManager';
import { GroupLevel, CategoryLevel, TaskLevel } from './ViewData';
import * as TaskConstants from '../../util/TaskConstants'
import texts from './ByDateTexts'
import * as DateUtil from '../../util/DateUtil'
import * as ViewHelper from '../../util/ViewHelper'
import { Fields as FilterFields } from '../../components/filter/FilterState';
import { Filter } from '../../components/filter/Filter';
import * as FilterHelper from '../../util/FilterHelper'
import pStyles from './ByDate.module.css';    // page-specific styles
import { SwitchView } from '../../components/switchView/SwitchView';
import {Notification} from '../../components/notification/Notification'
import gTexts from '../../resources/texts';

import Header from '../../components/layout/header'
import ViewFooter from '../../components/layout/viewFooter'
import gStyles from '../../styles/global.module.css';


TaskCell.toString();    // suppress warning

/**
 * View by Status Page
 * - container component
 */

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 * @property {Map} allGroups
 * @property {string} lastUpdated
 * @property {boolean} isShowMenuOptions
 * @property {boolean} isPageVertical
 * @property {boolean} isShowCompleted
 * @property {boolean} isExpandAll
 * @property {boolean} isShowFilter
 * @property {FilterFields} filterFields
 * @property {boolean} isShowViews
 * 
 */

/** @extends {React.Component<Props, State>} */
export default class ByDate extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        this.isShowCompleted = true;  // use local variable as the state is not created at this point
        this.allGroups = new Map();

        this.buildDataModel();

        /**@type {State} */
        this.state = {
            allGroups: this.allGroups,
            lastUpdated: StateManager.getData().tasks.lastUpdated,
            isShowMenuOptions: false,
            isPageVertical: true,
            isShowCompleted: this.isShowCompleted,
            isExpandAll: true,
            isShowFilter: false,
            filterFields: new FilterFields(),
            isShowViews: false,

        };
    }


    componentDidMount() {
        // log ('By Date did mount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.lastUpdated !== StateManager.getData().tasks.lastUpdated) {
            this.buildDataModel();  // rebuild allGroups when tasks changed (add, delete, update) - their categories may have changed
            this.setState({
                lastUpdated: StateManager.getData().tasks.lastUpdated,
                allGroups: this.allGroups,
            });
        }
    }

    render() {
      return (
        <div className={gStyles.pageBox}>
          <div className={gStyles.headerBox}>
            <Header title={texts.viewTitle} currPage={page.VIEW_BY_DATE} />
          </div>
          <div className={gStyles.bodyBox}>

                {/* filter */}
                <div className={pStyles.filterContainer}>
                    {this.state.isShowFilter &&
                        <Filter
                            currFilter={this.state.filterFields}
                            onSetFilter={this.onSetFilter}
                        />
                    }
                </div>

                <ByDateView
                    isPageVertical      ={this.state.isPageVertical}
                    allGroups           ={this.allGroups}
                />

          </div>
            <div className={gStyles.footerBox}>
              <ViewFooter 
                isShowCompleted     ={this.state.isShowCompleted}
                isExpandAll         ={this.state.isExpandAll}
                isPageVertical      ={this.state.isPageVertical}
                onNew               ={this.onClickCreate} 
                onExpandAll         ={this.onExpandAll}
                onShowCompleted     ={this.onShowCompleted}
                onFilter            ={this.onClickFilter}
                onPaging            ={this.onClickPaging}
          />
            </div>
    
          </div>
        )        

    }

    // *************** handle navbar events

    onClickBack = () => {
        goBack();
    }

    onClickCreate = () => {
        PageManager.clearTaskId();
        PageManager.goToPage(page.EDIT_TASK);
    }

    onClickSearch = () => {
        goToPage(page.SEARCH);
    }

    onClickMenuOptions = () => {
        this.setState({isShowMenuOptions: !this.state.isShowMenuOptions})
    }

    onClickHome = () => {
        goToPage(page.HOME);
    }

    onClickPaging = () => {
        Notification.snackBarInfo(this.state.isPageVertical ? gTexts.snackbarScrollLeftRight : gTexts.snackbarScrollUpDown);
        this.setState({isPageVertical: !this.state.isPageVertical});
    }

    onShowCompleted = () => {
        Notification.snackBarInfo(this.state.isShowCompleted ? gTexts.snackbarHideCompletedTasks : gTexts.snackbarShowCompletedTasks);
        this.isShowCompleted = !this.isShowCompleted;   // toggle the local variable
        this.setState({isShowCompleted: this.isShowCompleted}, this.updateDataModel)  
    }

    onClickSettings = () => {
        PageManager.goToPage(page.SYSTEM);
    }

    onExpandAll = () => {
        Notification.snackBarInfo(this.state.isExpandAll ? gTexts.snackbarCollapseAll : gTexts.snackbarExpandAll);
        this.setState({isExpandAll: !this.state.isExpandAll}, this.udpateExpandAll)
    }

    onSwitchView = () => {
        this.setState({ isShowViews: !this.state.isShowViews})
    }

    // ****************** handle view events

    /**
     * @param {string} groupId 
     */
    onToggleGroup = (groupId) => {
        /**@type {GroupLevel} */ const group = this.allGroups.get(groupId);
        group.isExpanded = !group.isExpanded; // toggle
        this.setState({ allGroups: this.allGroups });    
    }

    /**
     * @param {string} groupId 
     * @param {string} categoryId 
     */
    onToggleCategory = (groupId, categoryId) => {
        /**@type {GroupLevel} */ const group = this.allGroups.get(groupId);
        /**@type {CategoryLevel} */ const category = group.categoryMap.get(categoryId);
        category.isExpanded = !category.isExpanded; // toggle
        this.setState({ allGroups: this.allGroups});
    }
    
    /**
     * when click the create button per category
     * @param {import('react').SyntheticEvent<HTMLButtonElement>} e
     * @param {string} categoryId 
     */
    onCreateWithCategory = (e, categoryId) => {
        e.stopPropagation(); // prevent propagating event to other elements
        PageManager.clearTaskId();
        PageManager.setCategoryId(categoryId);
        PageManager.goToPage(page.EDIT_TASK);
    }

    /**
     * on clicking the task name
     * @param {string} id 
     */
    onSelect = (id) => {
        PageManager.setTaskId(id);
        PageManager.goToPage(page.EDIT_TASK);
    }

    /**
     * on clicking the check button
     * @param {import('react').SyntheticEvent<HTMLButtonElement>} e
     * @param {string} id 
     */
    onComplete = (e, id) => {
        e.stopPropagation();
        const task = TaskManager.getCell(id);
        if (task.status === TaskConstants.Status.DONE) {
            task.status = TaskConstants.Status.NOT_STARTED;
        } else {
            task.status = TaskConstants.Status.DONE;
        }
        TaskManager.upsertCell(task);
    }


    // ********************* build data model for the view to display

    buildDataModel = () => {
        log ('By date, build data model...');

        this.buildGroups();
        const allTasks = TaskManager.getAll();
        allTasks.forEach((task) => {
            if (FilterHelper.isPassFilter(task, this.isShowCompleted, Utils.isEmpty(this.state) ? null : this.state.filterFields)) {

                /**@type {boolean} */ let isFound = false;

                if ( isEmpty(task.startDate) && isEmpty(task.endDate) ) {
                    this.buildTask(task, TaskConstants.ByDate.NO_DATE);
                } else {
                    if (DateUtil.isBeforeThisWeek(task)) {
                        this.buildTask(task, TaskConstants.ByDate.PAST_DATE);
                        isFound = true;
                    }
                    if (DateUtil.isTodayDate(task)) {
                        this.buildTask(task, TaskConstants.ByDate.TODAY);
                        isFound = true;
                    }
                    if (DateUtil.isTomorrowDate(task)) {
                        this.buildTask(task, TaskConstants.ByDate.TOMORROW);
                        isFound = true;
                    }
                    if (DateUtil.isThisWeekDate(task)) {
                        this.buildTask(task, TaskConstants.ByDate.THIS_WEEK);
                        isFound = true;
                    }
                    if (DateUtil.isNextWeekDate(task)) {
                        this.buildTask(task, TaskConstants.ByDate.NEXT_WEEK);
                        isFound = true;
                    }
                    if (DateUtil.isAfterNextWeek(task)) {
                        this.buildTask(task, TaskConstants.ByDate.FUTURE);
                        isFound = true;
                    }
                    if (!isFound) {
                        // default
                        this.buildTask(task, TaskConstants.ByDate.FUTURE);
                    }
                }
            }
        })
    }

    /**
     * @param {TaskCell} task
     * @param {string} dateType
     */
    buildTask = (task, dateType) => {

        // group level
        const groupId = dateType;
                /**@type {GroupLevel} */ const groupLevel = this.allGroups.get(groupId);

        // category level
        const categoryId = task.categoryId;
        const categoryMap = groupLevel.categoryMap;
        if (!categoryMap.has(categoryId)) {  // save category only once
            const category = CategoryManager.getCategory(categoryId);
            if (!isEmpty(category)) {
                const categoryLevel = new CategoryLevel(category);
                categoryLevel.displayText = ViewHelper.getCategoryDisplayText(category);
                categoryMap.set(categoryId, categoryLevel);
            }
        }
                /**@type {CategoryLevel} */ const categoryLevel = categoryMap.get(categoryId);

        // task level
        if (!isEmpty(categoryLevel)) {
            const taskMap = categoryLevel.taskMap;
            taskMap.set(task.taskId, new TaskLevel(task));
        } else {
            logWarn('Missing categoryId', categoryId);
        }

    }

    buildGroups = () => {
        // insertion order will be used as sorting
        this.createGroup(TaskConstants.ByDate.PAST_DATE, texts.datePast);
        this.createGroup(TaskConstants.ByDate.TODAY, texts.dateToday);
        this.createGroup(TaskConstants.ByDate.TOMORROW, texts.dateTomorrow);
        this.createGroup(TaskConstants.ByDate.THIS_WEEK, texts.dateThisWeek);
        this.createGroup(TaskConstants.ByDate.NEXT_WEEK, texts.dateNextWeek);
        this.createGroup(TaskConstants.ByDate.FUTURE, texts.dateFuture);
        this.createGroup(TaskConstants.ByDate.NO_DATE, texts.dateNone);
    }
    /**
     * @param {string} id
     * @param {string} desc
     * @returns {GroupLevel}
     */
    createGroup = (id, desc) => {
        const group = new GroupLevel(id, desc);
        this.allGroups.set(id, group);
        return group
    }

    updateDataModel = () => {
        this.buildDataModel();  // rebuild allGroups when toggling to show completed tasks
        this.setState({ allGroups: this.allGroups});
    }

    udpateExpandAll = () => {
        
        this.allGroups.forEach( /**@param {GroupLevel} g */ (g) => {
            g.isExpanded = this.state.isExpandAll;
            g.categoryMap.forEach( /**@param {GroupLevel} c */ (c) => {
                c.isExpanded = this.state.isExpandAll;
            })
        });
        this.setState({allGroups: this.allGroups});
    }

    // ******************************** filter handlers

    /** show filter dialog */
    onClickFilter = () => {
        this.setState({isShowFilter: true});
    }

    /**
     * the handler when filters are set
     * valid values:
     *      null = filter dialog was closed, 
     *      empty fields = filters are cleared
     *      non-empty fields = filters are set
     * 
     * @param {FilterFields}  inFilterFields // 
     */
    onSetFilter = (inFilterFields) => {
        this.setState({isShowFilter: false});  // close the filter
        
        if (Utils.isEmpty(inFilterFields)) return; // do nothing

        // set filter only if there's difference from current state
        if (FilterHelper.isDiff(this.state.filterFields, inFilterFields)) {
            this.setState({filterFields: inFilterFields}, this.updateDataModel);  // rebuild data model 
        }
    }

}

