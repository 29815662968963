export default {

    viewTitle           : 'View by Hot Tasks',

    dateNone        : 'No Date',
    datePast        : 'Past Date',
    dateToday       : 'Today',
    dateTomorrow    : 'Tomorrow',
    dateThisWeek    : 'This Week',
    dateNextWeek    : 'Next Week',
    dateFuture      : 'In the Future',

    

}