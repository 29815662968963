import { TaskCell } from "../data/TaskCell";
import * as TaskConstants from './TaskConstants'
import { Utils } from "./Utils";
import { Fields as FilterFields } from '../components/filter/FilterState';

TaskCell.toString(); // suppress warning
FilterFields.toString(); // suppress warning

/**
 * @param {TaskCell} task
 */

 /**
  * 
  * @param {TaskCell} task 
  * @param {boolean} isShowCompleted 
  * @param {FilterFields} filterFields 
  */
export const isPassFilter = (task, isShowCompleted, filterFields) => {
    if (!isShowCompleted) {
        if (task.status === TaskConstants.Status.DONE) return false;
    }

    if (Utils.isEmpty(filterFields)) return true;  // skip if filters are not yet available (ie, called from constructor)

    if (hasFilter(filterFields)) {
        if (Utils.isNotEmpty(filterFields.categoryId)) {
            if (task.categoryId !== filterFields.categoryId) return false;
        }
        if (Utils.isNotEmpty(filterFields.status)) {
            if (task.status !== filterFields.status) return false;
        }
        if (Utils.isNotEmpty(filterFields.priority)) {
            if (task.priority !== filterFields.priority) return false;
        }
        if (Utils.isNotEmpty(filterFields.importance)) {
            if (task.importance !== filterFields.importance) return false;
        }
        if (Utils.isNotEmpty(filterFields.labelId)) {
            if (Utils.isEmpty(task.labels)) return false;
            if (!task.labels.includes(filterFields.labelId)) return false;
        }
    }
    return true;
}

/**
 * @param {FilterFields} filterFields
 * @returns {boolean} */
export const hasFilter = (filterFields) => {
    if (Utils.isNotEmpty(filterFields.categoryId)) return true;
    if (Utils.isNotEmpty(filterFields.labelId)) return true;
    if (Utils.isNotEmpty(filterFields.status)) return true;
    if (Utils.isNotEmpty(filterFields.priority)) return true;
    if (Utils.isNotEmpty(filterFields.importance)) return true;
    return false;
}

/**
 * check if two filters are different
 * @param {FilterFields} filter1,
 * @param {FilterFields} filter2,
 * @returns {boolean}
 */
export const isDiff = (filter1, filter2) => {
    const f1 = JSON.stringify(filter1);
    const f2 = JSON.stringify(filter2);
    return (f1 !== f2);
}
