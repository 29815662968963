import * as TaskConstants from '../util/TaskConstants'


export class TaskCategory {

        // identification
        
        /**@type {string} */ cellType = 'TASK_CATEGORY';        // REQUIRED !
        /**@type {string} */ categoryId = '';               // REQUIRED !
        /**@type {string} */ name = '';                         // REQUIRED !
        /**@type {string} */ parentCategoryId = TaskConstants.Category.ROOT;             // REQUIRED !

        // styling

        /**@type {string} */ image = '';                // icon filename
        /**@type {string} */ backgroundColor = '';      // in #FFFFFF
        /**@type {string} */ fontColor = '';

    
}


