import React from 'react';
import texts from './HomeTexts';
import pStyles from './Home.module.css';
import gStyles from '../../App.module.css';
import '../../resources/sprites.css';
import * as icon from '../../util/IconConstants';

export const Section = {
  CREATE: 'create',
  SEARCH: 'search',
  VIEW: 'view',
  APPS: 'apps',
  SETUP: 'setup',
  INFO: 'info'
}

// Home Page View - must be stateless

/**
 * @typedef {Object} Props
 * @property { import('./HomeState').Expanded } expanded
 * @property { ()=>void} onClickCreate
 * @property { ()=>void} onClickSearch
 * @property { ()=>void} onClickByCategory
 * @property { ()=>void} onClickByHotTask
 * @property { ()=>void} onClickByPriority
 * @property { ()=>void} onClickByImportance
 * @property { ()=>void} onClickByStatus
 * @property { ()=>void} onClickByDate
 * @property { ()=>void} onClickByLabel
 * @property { ()=>void} onClickGotoApps
 * @property { ()=>void} onClickSetupCategories
 * @property { ()=>void} onClickSetupLabels
 * @property { ()=>void} onClickSettings
 * @property { ()=>void} onClickAbout
 * @property { ()=>void} onClickHowToUse
 * @property { ()=>void} onClickTipsTricks
 * @property { (section:string)=>void} onToggleSection
 */

/**@param {Props} props */
export const HomeView = (props) => (
  <main className={[, pStyles.main].join(' ')}>

    {/* images */}
    <div className={`${pStyles.carousel}`}>
      <div className={pStyles.carouselBox}>
        <button className={`${gStyles.btnImg} ${icon.CAROUSEL_TASKS}`}></button>
        <span>{texts.imgTodo}</span>
      </div>
      <div className={pStyles.carouselBox}>
        <button className={`${gStyles.btnImg} ${icon.CAROUSEL_CATEGORIES}`}></button>
        <span>{texts.imgCategory}</span>
      </div>
      <div className={pStyles.carouselBox}>
        <button className={`${gStyles.btnImg} ${icon.CAROUSEL_SCHEDULE}`}></button>
        <span>{texts.imgSchedule}</span>
      </div>
      <div className={pStyles.carouselBox}>
        <button className={`${gStyles.btnImg} ${icon.CAROUSEL_SORT}`}></button>
        <span>{texts.imgSort}</span>
      </div>
      <div className={pStyles.carouselBox}>
        <button className={`${gStyles.btnImg} ${icon.CAROUSEL_SEARCH}`}></button>
        <span>{texts.imgSearch}</span>

      </div>
    </div>

    <div className={pStyles.caption}>
      <span>{texts.homeCaption}</span>
    </div>

    <div className={[pStyles.header].join(' ')} >
        <span> {texts.startHeader} </span>
      </div>
      <hr/>

    <div className={`${gStyles.btnRound} ${pStyles.buttonBox}`} onClick={props.onClickCreate}>{texts.createTask}</div>
    <div className={`${gStyles.btnRound} ${pStyles.buttonBox}`} onClick={props.onClickByCategory}>{texts.viewTask}</div>



    {/* info */}
    <div>
      <div className={[pStyles.header].join(' ')} >
        <span > {texts.infoHeader} </span>
      </div>
      <hr/>

      <section className={pStyles.list} >
        <div onClick={props.onClickAbout} className={pStyles.listItem}>{texts.iWantToKnow} {' '} <span className={gStyles.link} >{texts.about}</span> </div>
        <div onClick={props.onClickHowToUse} className={pStyles.listItem}>{texts.iWantToKnow} {' '} <span className={gStyles.link} >{texts.howToUse}</span> </div>
        <div onClick={props.onClickTipsTricks} className={pStyles.listItem}>{texts.iWantToKnow} {' '} <span className={gStyles.link} >{texts.tipsTricks}</span> </div>
      </section>
    </div>

  </main>
);


