export default {
    descriptionHeader   : 'Description',
    detailsHeader       : 'Details',
    dueDatesHeader      : 'Due Dates',
    labelsHeader        : 'Labels',
    nameLabel           : 'Name:',
    nameHint            : 'Enter task name',
    notesHint           : 'Enter additional info to help complete this task',
    categoryLabel       : 'Category:',
    statusLabel         : 'Status:',
    priorityLabel       : 'Priority:',
    importanceLabel     : 'Importance:',
    notesLabel          : 'Notes:',
    startDateLabel      : 'Start Date:',
    endDateLabel        : 'End Date:',
    repeatLabel         : 'Repeat:',
    tagLabel            : 'Labels & Tags:',

    // help
    categoryInfo        : {title:'Category',content:'The category allows you to group your tasks in a hierarchy. A category with >> symbol has more sub-categories.'},
    statusInfo          : {title:'Status',content:'Set the status for this task depending on its state and progress.'},
    priorityInfo        : {title:'Priority',content:'Set the priority for this task. Priority is usually based on urgency and dependency.'},
    importanceInfo      : {title:'Importance',content:'Set the relative importance of this task. Importance indicates necessity and usefulness.'},
    notesInfo           : {title:'Notes',content:'Add details to this task, you can write text or links.'},
    startDateInfo       : {title:'Start Date',content:'Set the start date for this task. Together with end date, they determine the duration for this task.'},
    endDateInfo         : {title:'End Date',content:'Set the completion date for this task. Together with start date, they determine the duration for this task.'},
    repeatInfo          : {title:'Repeat',content:'Select if you want this task to repeat. You must set start date to repeat a task.'},
    labelInfo           : {title:'Labels',content:'Labels allow you to put tags on your tasks to group them cross-sectionally. If category is vertical grouping, label is horizontal grouping.'},

    // error messages
    emptyNameError      : 'You forgot to input task name, duh!',
    emptyCategoryError  : 'Hey, you forgot to choose a category! You can create one by tapping the list button on the side.',
    startDateError      : 'Oops, can\'t parse the start date!',
    endDateError        : 'Oops, can\'t parse the end date!',
    startDatePast       : 'Ahem, this date is in the past. Time travel perhaps?',
    startDateTooFar     : 'Whoa, that date is too far in the future! Maybe this app doesn\'t exist anymore!',
    dateRangeError      : `Tsk-tsk, the end date is before the start date, you're finished before you get started.`,
    dateRangeTooFar     : 'The duration for this task is more than a year! Are you sure you have the energy for that?',
    repeatError         : 'You must enter start date for repeating task!',

    saveError           : 'Error saving task: ', 
    deleteError         : 'Error deleting task: ', 

    deleteWarning       : {title:'Delete Task',content:'Hold it there, this will delete the task and cannot be undone. Would you like to continue?'},

    // popup messages
    validationError     : 'Uh oh, errors found!',
    save                : 'Saving task...',
    saveNew             : 'Saving & new...',
    delete              : 'Deleting task...'
}