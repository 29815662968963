import React from 'react'

import '../../resources/sprites2.css'
import * as icon from '../../util/IconConstants'
import * as page from '../../util/PageConstants'
import { goToPage, goBack } from '../../navigation/PageManager'

import texts from './HomeTexts';
import styles from './Home.module.css';

function Footer({ showNew = true, onNew = () => goToPage(page.EDIT_TASK) }) {
  return (
    <div className={styles.footer}>

      <span>{texts.copyRight}</span>

      <span>{texts.company}</span>

    </div>
  )
}

export default Footer;