import React from 'react';

import CategoryCard from '../../components/categoryCard/CategoryCard';

import gStyles from '../../App.module.css';     // global styles
import pStyles from './ByCategory.module.css';    // page-specific styles
import gTexts from '../../resources/texts'
import '../../resources/sprites.css'
import '../../resources/sprites2.css'
import * as icon from '../../util/IconConstants'

import { isEmpty } from '../../util/Utils';
import { CategoryLevel, GroupLevel, TaskLevel } from './ViewData'
import * as TaskConstants from '../../util/TaskConstants'

CategoryLevel.toString();    // suppress warning
GroupLevel.toString();    // suppress warning
TaskLevel.toString();    // suppress warning

/* 
    Presentation component for Category View Page
    - should be stateless
*/


/**
 * @typedef {Object} Props
 * @property {boolean} isPageVertical
 * @property {Map} allGroups
 */


/** @param {Props} props */
export const ByCategoryView = (props) => (
    <main className={[
            props.isPageVertical ? gStyles.colFlex : gStyles.horizontalScroll,
            ].join(' ')}>

        {/* loop through hierarchies */}

        {props.allGroups.size > 0 &&
            <div>
                {buildGroup(props)}
            </div>
        }


    </main>
)

/**
 * build Group Level component
 * @param {Props} props 
 */
const buildGroup = (props) => {
    /**@type {GroupLevel[]} */ const groups = Array.from(props.allGroups.values());
    const groupView = groups.map((g, i) => {

        return (
            <div key={i} className={[
                    props.isPageVertical ? "" : gStyles.horizontalChild,
                    pStyles.groupSection].join(' ')} >

                <div className={pStyles.groupWrapper}>
                    {buildCategory(g)}
                </div>

            </div>
        )
    })
    return groupView;
}

/**
 * build category level component
 * @param {GroupLevel} group 
 */
 const buildCategory = (group) => {
  const categories = Array.from(group.categoryMap.values());
  const categoryView = categories.map((c, i) => (
    <CategoryCard key={i} category={c} />
  ))
  return categoryView;
}
