import React from 'react'

import '../../resources/sprites2.css'
import * as icon from '../../util/IconConstants'
import { goToPage, goBack } from '../../navigation/PageManager'

import layout from './layout.module.css';
import gStyles from '../../App.module.css';

function ViewFooter({
  isShowCompleted,
  isExpandAll,
  isPageVertical,
  onNew,
  onExpandAll,
  onShowCompleted,
  onFilter,
  onPaging
}) {
  return (
    <div className={layout.footer}>

      <button className={[gStyles.btnIconFilled, icon.BACK,].join(' ')} onClick={() => goBack()} ></button>

      <button onClick={onFilter} className={[gStyles.btnIconFilled, icon.FILTER,].join(' ')} ></button>
      <button onClick={onExpandAll} className={[gStyles.btnIconFilled, isExpandAll ? icon.COLLAPSE_ALL : icon.EXPAND_ALL,].join(' ')} ></button>
      <button onClick={onShowCompleted} className={[gStyles.btnIconFilled, isShowCompleted ? icon.HIDE_COMPLETED : icon.SHOW_COMPLETED,].join(' ')} ></button>
      <button onClick={onPaging} className={[gStyles.btnIconFilled, isPageVertical ? icon.PAGE_HORIZONTAL : icon.PAGE_VERTICAL,].join(' ')} ></button>
      <button onClick={onNew} className={[gStyles.btnIconFilled, icon.NEW_TASK,].join(' ')} ></button>

    </div>
  )
}

export default ViewFooter;