import React from 'react';

import GroupCard from '../../components/groupCard/GroupCard';
import { GroupLevel } from './ViewData'
import gStyles from '../../App.module.css';     // global styles

GroupLevel.toString();    // suppress warning

/* 
    Presentation component for Priority View Page
    - should be stateless

    by Ludwin 2019/11/18
*/


/**
 * @typedef {Object} Props
 * @property {boolean} isPageVertical
 * @property {Map} allGroups
 */


/** @param {Props} props */
export const ByPriorityView = (props) => (
    <main className={[
            props.isPageVertical ? gStyles.colFlex : gStyles.horizontalScroll,
            ].join(' ')}>

        {/* loop through hierarchies */}
        {props.allGroups.size > 0 &&
            <div>
                {buildGroup(props)}
            </div>
        }


    </main>
)

/**
 * build Group Level component
 * @param {Props} props 
 */
const buildGroup = (props) => {
    /**@type {GroupLevel[]} */ const groups = Array.from(props.allGroups.values());
    const groupView = groups.map((g, i) => (
      <GroupCard key={i} group={g} isPageVertical={props.isPageVertical} />
    ))
    return groupView;
}

