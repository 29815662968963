import { TaskCategory } from "../../data/TaskCategory";
import { TaskCell } from "../../data/TaskCell";

TaskCategory.toString();    // suppress warning
TaskCell.toString();        // suppress warning

export class GroupLevel {
    /**
     * 
     * @param {string} id 
     * @param {string} desc 
     */
    constructor(id, desc) {
        this.groupId = id;
        this.groupDesc = desc;
        this.isExpanded = true;
        this.categoryMap = new Map();

    }
}

export class CategoryLevel {

    /**@param {TaskCategory} category */
    constructor(category) {
        this.categoryId = category.categoryId;
        this.category = category;
        this.displayText = category.name;  // override with formatted text
        this.isExpanded = true;
        this.taskMap = new Map();
    }

}

export class TaskLevel {

    /**
     * 
     * @param {TaskCell} task 
     */
    constructor(task) {
        this.taskId = task.taskId;
        this.task = task;

    }
}
