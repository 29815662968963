import React from 'react'
import styles from './ShortcutMenu.module.css'
import gStyles from '../../App.module.css'
import { Utils } from '../../util/Utils'
import texts from './ShortcutMenuTexts'
import { goToPage, getCurrentPage, clearTaskId } from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import * as icon from '../../util/IconConstants'

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 */

/** @extends {React.Component<Props, State>} */
export class ShortcutMenu extends React.Component {

    /**@param {Props} props */
    constructor(props) {
        super(props);

        this.state = {
            isShow: false,
        }

    }

    onToggle = () => {
        this.setState({ isShow: !this.state.isShow })
    }

    /**@param {string} inPage */
    onSelect = (inPage) => {
        Utils.debug('shortcut', inPage);
        this.setState({ isShow: false });
        if (getCurrentPage() !== inPage) {
            if (inPage === page.EDIT_TASK) {
                clearTaskId();  // create new task
            }
            goToPage(inPage);
        }
    }

    render() {

        return (
            <div className={[styles.shortcut].join(' ')}>

                <div
                    className={[styles.shortcutGroup].join(' ')}
                    style={{ display: this.state.isShow ? 'block' : 'none' }}
                >
                    <div 
                        onClick={() => this.onSelect(page.EDIT_TASK)}
                        className={[icon.SHORTCUT_MENU_ADD, gStyles.btnIcon, styles.shortcutItem].join(' ')}
                        title={texts.shortCutAddTip}                        
                    ></div>
                    <div onClick={() => this.onSelect(page.SEARCH)}
                        className={[icon.SHORTCUT_MENU_SEARCH, gStyles.btnIcon, styles.shortcutItem].join(' ')}
                        title={texts.shortCutSearchTip}
                    ></div>
                    <div onClick={() => this.onSelect(page.HOME)}
                        className={[icon.SHORTCUT_MENU_HOME, gStyles.btnIcon, styles.shortcutItem].join(' ')}
                        title={texts.shortCutHomeTip}
                    ></div>

                </div>

                <div 
                    onClick={this.onToggle}
                    className={[icon.SHORTCUT_MENU, gStyles.btnIcon, styles.shortcutMainItem].join(' ')}
                    title={texts.shortcutTip}
                    ></div>


            </div>
        )
    }


}



