/**
 * hack to import raw html into js
 * 
 * Tip: copy html code into .html file for easy editing and paste back here
 */

 export const rawHtml = `
 <style>
  .section {
    line-height: 22px;
    font-size: 14px;
  }

  .productName {
    font-weight: 500;
    color: darkslateblue;
  }

  .head1 {
    line-height: 28px;
    font-size: 20px;
  }

  .list {
    list-style-type: disc;
  }

  ul, ol {
    margin-top: -8px;
  }
  li {
    padding-top: 8px;
  }

  .emphasis {
    font-style: italic;
  }

  .spacer {
    margin-top: 16px;
  }
</style>
<div class="section">
  <p>
    <span class="productName">MyBrainMate Task</span> is a tool to manage your tasks.
  <p>
    It helps you to become more efficient in using your time and energy.
  <p class="head1">
    How to Use
  <p>
    Generally, there are two ways to use <span class="productName">MyBrainMate Task</span>
  <ol>
    <li>Create tasks</li>
    <li>View tasks</li>
    </ol>
  <p class="head1">
    Create Tasks
  <p>
    When you create task, you can specify the following attributes
  <ul class="list">
    <li>Name</li>
    <li>Status</li>
    <li>Priority</li>
    <li>Notes</li>
    <li>Start/End Dates</li>
    <li>Labels</li>
  </ul>
  <p>
    These attributes add value and meaning to your tasks.
  <p class="head1">
    View Tasks
  <p>
    A view shows your tasks in a specific manner. You can think of views as different angles of looking at the same
    tasks. Each angle gives you a different perspective of your tasks.
  <p>
    There are several ways of viewing your tasks
  <ol>
    <li>View by Category </li>
    show tasks in hierarchy, useful in planning purposes
    <li>View by Hot Tasks </li>
    show urgent and important tasks so you can prioritize your time properly
    <li>View by Date </li>
    show tasks on a timeline, this gives you an outlook of the days to come
    <li>View by Status </li>
    show tasks as they progress from start to completion
    <li>View by Priority </li>
    show tasks by descending priority
    <li>View by Label </li>
    show tasks by the tags you set them, it gives you a cross-sectional view of your tasks
  </ol>
</div>
`