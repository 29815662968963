import React from 'react'
// import gStyles from '../../App.module.css';

import { goBack } from '../../navigation/PageManager'
import texts from './AboutTexts'
import * as page from '../../util/PageConstants'
import Header from '../../components/layout/header'
import Footer from '../../components/layout/footer'
import gStyles from '../../styles/global.module.css';

import {rawHtml} from './about_html'

export default class About extends React.Component {

  onBack = () => {
    goBack()
  }

  render() {
    return (
      <div className={gStyles.pageBox}>
        <div className={gStyles.headerBox}>
          <Header title={texts.aboutTitle} currPage={page.ABOUT} />
        </div>
        <div className={gStyles.bodyBox}>
          <main
            dangerouslySetInnerHTML={{
              __html: rawHtml
            }}
          >
          </main>

        </div>
        <div className={gStyles.footerBox}>
          <Footer />
        </div>

      </div>
    )


  }

}