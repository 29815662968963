import { TaskCategory } from "../data/TaskCategory"
import { TaskLabel } from "../data/TaskLabel"
import * as DataUtil from '../data/DataUtil'
import { CategoryLevel } from "../pages/viewByImportance/ViewData";
import * as TaskConstants from '../util/TaskConstants'
import gTexts from '../resources/texts'
import {isNotEmpty} from '../util/Utils'

TaskCategory.toString();    // suppress warning
TaskLabel.toString();        // suppress warning
CategoryLevel.toString();   // suppress warning

/**
 * @param {TaskCategory} category 
 * @returns {string}
 */
export const getCategoryDisplayText = (category) => {
    if (DataUtil.isRoot(category)) return category.name;

    let topAncestor = '', arrows='';

    const ancestors = DataUtil.getAncestors(category);
    if (isNotEmpty(ancestors)) {
        topAncestor = ancestors[ancestors.length-1].name;
        arrows = ' ' + ancestors.map(()=>'>').join('') + ' ';   // the no. of arrows equal the ancestors
    }
    const displayText = topAncestor + arrows + category.name;
    return displayText;
}

/**@param {CategoryLevel[]} categoryLevels */
export const sortCategoryNames = (categoryLevels) => {
    categoryLevels.sort( (a,b) => {
        const name1 = a.displayText.toLowerCase();
        const name2 = b.displayText.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
    });
}

/**@param {TaskLabel[]} labels */
export const sortLabels = (labels) => {
    labels.sort( (a,b) => {
        const name1 = a.name.toLowerCase();
        const name2 = b.name.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
    });
}

/**@param {string} code */
export const getStatusDescription = (code) => {
    switch (code) {
        case TaskConstants.Status.NOT_STARTED: return gTexts.statusNotStarted
        case TaskConstants.Status.IN_PROGRESS: return gTexts.statusInProgress
        case TaskConstants.Status.DONE: return gTexts.statusDone
        case TaskConstants.Status.WAITING: return gTexts.statusWaiting
        case TaskConstants.Status.CANCELLED: return gTexts.statusCancelled
        default: return '';
    }
}

/**@param {string} code */
export const getPriorityDescription = (code) => {
    switch (code) {
        case TaskConstants.Priority.VERY_HIGH: return gTexts.priorityVeryHigh
        case TaskConstants.Priority.HIGH: return gTexts.priorityHigh
        case TaskConstants.Priority.NORMAL: return gTexts.priorityNormal
        case TaskConstants.Priority.LOW: return gTexts.priorityLow
        case TaskConstants.Priority.VERY_LOW: return gTexts.priorityVeryLow
        default: return '';
    }
}

/**@param {string} code */
export const getImportanceDescription = (code) => {
    switch (code) {
        case TaskConstants.Priority.VERY_HIGH: return gTexts.importanceVeryHigh
        case TaskConstants.Priority.HIGH: return gTexts.importanceHigh
        case TaskConstants.Priority.NORMAL: return gTexts.importanceNormal
        case TaskConstants.Priority.LOW: return gTexts.importanceLow
        case TaskConstants.Priority.VERY_LOW: return gTexts.importanceVeryLow
        default: return '';
    }
}

/**@param {string} code */
export const getDateSelectorDescription = (code) => {
    switch (code) {
        case TaskConstants.DateSelector.NONE: return gTexts.dateSelectorNone
        case TaskConstants.DateSelector.TODAY: return gTexts.dateSelectorToday
        case TaskConstants.DateSelector.TOMORROW: return gTexts.dateSelectorTomorrow
        case TaskConstants.DateSelector.WEEK1: return gTexts.dateSelectorWeek1
        case TaskConstants.DateSelector.WEEK2: return gTexts.dateSelectorWeek2
        case TaskConstants.DateSelector.MONTH1: return gTexts.dateSelectorMonth1
        case TaskConstants.DateSelector.DATE: return gTexts.dateSelectorDate
        default: return '';
    }
}

/**@param {string} code */
export const getRepeatDescription = (code) => {
    switch (code) {
        case TaskConstants.RepeatSelector.NONE: return gTexts.repeatSelectorNone
        case TaskConstants.RepeatSelector.DAILY: return gTexts.repeatSelectorDaily
        case TaskConstants.RepeatSelector.WEEKLY: return gTexts.repeatSelectorWeekly
        case TaskConstants.RepeatSelector.MONTHLY: return gTexts.repeatSelectorMonthly
        case TaskConstants.RepeatSelector.YEARLY: return gTexts.repeatSelectorYearly
        default: return '';
    }
}
