import React from 'react';
import * as TaskManager from '../../data/TaskManager'
import * as CategoryManager from '../../data/CategoryManager'

import { ByCategoryNavbar } from './ByCategoryNavBar';
import { ByCategoryView } from './ByCategoryView'

import { goToPage, goBack } from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import { TaskCell } from '../../data/TaskCell';
import { isEmpty, logWarn, Utils } from '../../util/Utils';
import * as DataUtil from '../../data/DataUtil';
import { StateManager } from '../../stateManager/StateManager';
import { GroupLevel, CategoryLevel, TaskLevel } from './ViewData';
import * as TaskConstants from '../../util/TaskConstants'
import * as ViewHelper from '../../util/ViewHelper'
import { Fields as FilterFields } from '../../components/filter/FilterState';
import { Filter } from '../../components/filter/Filter';
import * as FilterHelper from '../../util/FilterHelper'
import pStyles from './ByCategory.module.css';    // page-specific styles
import { SwitchView } from '../../components/switchView/SwitchView';
import {Notification} from '../../components/notification/Notification'
import gTexts from '../../resources/texts';

import texts from './ByCategoryTexts'

import Header from '../../components/layout/header'
import ViewFooter from '../../components/layout/viewFooter'
import gStyles from '../../styles/global.module.css';

TaskCell.toString();    // suppress warning

/**
 * View by Category Page
 * - container component
 * 
 * by Ludwin on 2019/11/17
 */

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 * @property {Map} allGroups
 * @property {string} lastUpdated
 * @property {boolean} isShowMenuOptions
 * @property {boolean} isPageVertical
 * @property {boolean} isShowCompleted
 * @property {boolean} isExpandAll
 * @property {boolean} isShowFilter
 * @property {FilterFields} filterFields
 * @property {boolean} isShowViews
 * 
 */

/** @extends {React.Component<Props, State>} */
export default class ByCategory extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        this.isShowCompleted = true;  // use local variable as the state is not created at this point
        this.allGroups = new Map();

        this.buildDataModel();

        /**@type {State} */
        this.state = {
            allGroups: this.allGroups,
            lastUpdated: StateManager.getData().tasks.lastUpdated,
            isShowMenuOptions: false,
            isPageVertical: true,
            isShowCompleted: this.isShowCompleted,
            isExpandAll: true,
            isShowFilter: false,
            filterFields: new FilterFields(),
            isShowViews: false,

        };
    }


    componentDidMount() {
        // log ('Importance did mount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.lastUpdated !== StateManager.getData().tasks.lastUpdated) {
            this.buildDataModel();  // rebuild allGroups when tasks changed (add, delete, update) - their categories may have changed
            this.setState({
                lastUpdated: StateManager.getData().tasks.lastUpdated,
                allGroups: this.allGroups,
            });
        }
    }

    render() {
        // log ('Importance, render...');
        return (
          <div className={gStyles.pageBox}>
            <div className={gStyles.headerBox}>
              <Header title={texts.viewTitle} currPage={page.VIEW_BY_CATEGORY} />
            </div>
            <div className={gStyles.bodyBox}>
                {/* filter */}
                <div className={pStyles.filterContainer}>
                    {this.state.isShowFilter &&
                        <Filter
                            currFilter={this.state.filterFields}
                            onSetFilter={this.onSetFilter}
                        />
                    }
                </div>

                <ByCategoryView
                    isPageVertical          ={this.state.isPageVertical}
                    allGroups               ={this.allGroups}
                />                
    
            </div>
            <div className={gStyles.footerBox}>
              <ViewFooter 
                isShowCompleted     ={this.state.isShowCompleted}
                isExpandAll         ={this.state.isExpandAll}
                isPageVertical      ={this.state.isPageVertical}
                onNew               ={this.onClickCreate} 
                onExpandAll         ={this.onExpandAll}
                onShowCompleted     ={this.onShowCompleted}
                onFilter            ={this.onClickFilter}
                onPaging            ={this.onClickPaging}
          />
            </div>
    
          </div>
        )        
    }

    // *************** handle navbar events

    onClickBack = () => {
        goBack();
    }

    onClickCreate = () => {
        PageManager.clearTaskId();
        PageManager.goToPage(page.EDIT_TASK);
    }

    onClickSearch = () => {
        goToPage(page.SEARCH);
    }

    onClickMenuOptions = () => {
        this.setState({isShowMenuOptions: !this.state.isShowMenuOptions})
    }

    onClickHome = () => {
        goToPage(page.HOME);
    }

    onClickPaging = () => {
        Notification.snackBarInfo(this.state.isPageVertical ? gTexts.snackbarScrollLeftRight : gTexts.snackbarScrollUpDown);
        this.setState({isPageVertical: !this.state.isPageVertical});
    }

    onShowCompleted = () => {
        Notification.snackBarInfo(this.state.isShowCompleted ? gTexts.snackbarHideCompletedTasks : gTexts.snackbarShowCompletedTasks);
        this.isShowCompleted = !this.isShowCompleted;   // toggle the local variable
        this.setState({isShowCompleted: this.isShowCompleted}, this.updateDataModel)  
    }

    onClickSettings = () => {
        PageManager.goToPage(page.SYSTEM);
    }

    onExpandAll = () => {
        Notification.snackBarInfo(this.state.isExpandAll ? gTexts.snackbarCollapseAll : gTexts.snackbarExpandAll);
        this.setState({isExpandAll: !this.state.isExpandAll}, this.udpateExpandAll)
    }

    onSwitchView = () => {
        this.setState({ isShowViews: !this.state.isShowViews})
    }

    // ****************** handle view events

    /**
     * @param {string} groupId 
     */
    onToggleGroup = (groupId) => {
        /**@type {GroupLevel} */ const group = this.allGroups.get(groupId);
        group.isExpanded = !group.isExpanded; // toggle
        this.setState({ allGroups: this.allGroups });    
    }

    /**
     * @param {string} groupId 
     * @param {string} categoryId 
     */
    onToggleCategory = (groupId, categoryId) => {
        /**@type {GroupLevel} */ const group = this.allGroups.get(groupId);
        /**@type {CategoryLevel} */ const category = group.categoryMap.get(categoryId);
        category.isExpanded = !category.isExpanded; // toggle
        this.setState({ allGroups: this.allGroups});
    }
    
    /**
     * when click the create button per category
     * @param {import('react').SyntheticEvent<HTMLButtonElement>} e
     * @param {string} categoryId 
     */
    onCreateWithCategory = (e, categoryId) => {
        e.stopPropagation(); // prevent propagating event to other elements
        PageManager.clearTaskId();
        PageManager.setCategoryId(categoryId);
        PageManager.goToPage(page.EDIT_TASK);
    }

    /**
     * on clicking the task name
     * @param {string} id 
     */
    onSelect = (id) => {
        PageManager.setTaskId(id);
        PageManager.goToPage(page.EDIT_TASK);
    }

    /**
     * on clicking the check button
     * @param {import('react').SyntheticEvent<HTMLButtonElement>} e
     * @param {string} id 
     */
    onComplete = (e, id) => {
        e.stopPropagation();
        const task = TaskManager.getCell(id);
        if (task.status === TaskConstants.Status.DONE) {
            task.status = TaskConstants.Status.NOT_STARTED;
        } else {
            task.status = TaskConstants.Status.DONE;
        }
        TaskManager.upsertCell(task);
    }


    // ********************* build data model for the view to display

    buildDataModel = () => {
        this.buildGroups();

        this.buildCategories();

        const allTasks = TaskManager.getAll();
        allTasks.forEach((task) => {
            if (FilterHelper.isPassFilter(task, this.isShowCompleted, Utils.isEmpty(this.state) ? null : this.state.filterFields)) {
                this.buildTasks(task);
            }
        })
    }

    buildGroups = () => {
        const categories = DataUtil.getCategoriesHierarchy(); // sorted by categories/subcategories
        let prevRootId = '';
        let isFirst = true;
        categories.forEach( (category) => {
            const nextRootId = DataUtil.getTopAncestor(category).categoryId;
            if (isFirst) {
                prevRootId = nextRootId;
                isFirst = false;
            } else if ( prevRootId !== nextRootId) {  // new root category
                this.createGroup(prevRootId);
                prevRootId = nextRootId;
            }
        })
        if ( !isEmpty(prevRootId)) {
            this.createGroup(prevRootId);  // include the last group
        }
    }

    /** @param {string} id */
    createGroup = (id) => {
        const group = new GroupLevel(id, '');
        const category = CategoryManager.getCategory(id);
        group.groupDesc = category.name;
        this.allGroups.set(id, group);
    }

    buildCategories = () => {
        const categories = DataUtil.getCategoriesHierarchy();
        // include all even empty categories
        categories.forEach( (category) => {
            // todo: check filter here
            const groupId = DataUtil.getTopAncestor(category).categoryId;  // root category id
            /**@type {GroupLevel} */ const groupLevel = this.allGroups.get(groupId);
            const categoryMap = groupLevel.categoryMap;

            const categoryId = category.categoryId;
            if (!categoryMap.has(categoryId)) {  // save category only once
                const categoryLevel = new CategoryLevel(category);
                categoryLevel.displayText = ViewHelper.getCategoryDisplayText(category);
                categoryMap.set(categoryId, categoryLevel);
            }
        })
    }    
    
    /**@param {TaskCell}  task */
    buildTasks = (task) => {
        const categoryId = task.categoryId;

        const category = CategoryManager.getCategory(categoryId);
        if (isEmpty(category)) {
            logWarn('Cant find category! Missing categoryId', task.name);
            return;
        }

        // group level
        const groupId = DataUtil.getTopAncestor(category).categoryId;  // root category id
        /**@type {GroupLevel} */ const groupLevel = this.allGroups.get(groupId);
        if (isEmpty(groupLevel)) {
            logWarn('Cant find group level! Missing categoryId', categoryId);
            return;
        }

        // category level
        const categoryMap = groupLevel.categoryMap;
        /**@type {CategoryLevel} */ const categoryLevel = categoryMap.get(categoryId);
        if (isEmpty(categoryLevel)) {
            logWarn('Cant find category level! Missing categoryId', categoryId);
            return;
        }

        // task level
        const taskMap = categoryLevel.taskMap;
        taskMap.set(task.taskId, new TaskLevel(task));

    }

    updateDataModel = () => {
        this.buildDataModel();  // rebuild allGroups when toggling to show completed tasks
        this.setState({ allGroups: this.allGroups});
    }

    udpateExpandAll = () => {
        
        this.allGroups.forEach( /**@param {GroupLevel} g */ (g) => {
            g.isExpanded = this.state.isExpandAll;
            g.categoryMap.forEach( /**@param {GroupLevel} c */ (c) => {
                c.isExpanded = this.state.isExpandAll;
            })
        });
        this.setState({allGroups: this.allGroups});
    }

    // ******************************** filter handlers

    /** show filter dialog */
    onClickFilter = () => {
        this.setState({isShowFilter: true});
    }

    /**
     * the handler when filters are set
     * valid values:
     *      null = filter dialog was closed, 
     *      empty fields = filters are cleared
     *      non-empty fields = filters are set
     * 
     * @param {FilterFields}  inFilterFields // 
     */
    onSetFilter = (inFilterFields) => {
        this.setState({isShowFilter: false});  // close the filter
        
        if (Utils.isEmpty(inFilterFields)) return; // do nothing

        // set filter only if there's difference from current state
        if (FilterHelper.isDiff(this.state.filterFields, inFilterFields)) {
            this.setState({filterFields: inFilterFields}, this.updateDataModel);  // rebuild data model 
        }
    }

}

