import React from 'react';

// import * as DataManager from '../../data/DataManager'
import * as CategoryManager from '../../data/CategoryManager'
import { TaskCategory } from '../../data/TaskCategory';

import { EditCategoryNavbar } from './EditCategoryNavbar'
import { EditCategoryView, Section } from './EditCategoryView';

import * as PageManager from '../../navigation/PageManager';
import * as TaskConstants from '../../util/TaskConstants'
import texts from './EditCategoryTexts'

import { isEmpty, Utils } from '../../util/Utils'

import { State, Category, Errors } from './CategoryState'  // alias for the State type
import * as page from '../../util/PageConstants'
import * as DataUtil from '../../data/DataUtil'
import * as ViewHelper from '../../util/ViewHelper'
import { Notification, NotificationType } from '../../components/notification/Notification';

Errors.toString();    // suppress warning

/**
 * @typedef {Object} Props - create an alias for props
 */


/** @type {State} */
/** @extends {React.Component<Props, State>} */
export default class EditCategory extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        this.origTaskCategory = null;
        this.origStateFields = '';
        this.currCategoryId = PageManager.getCategoryId(); // to save passed in id to local variable so local update is synchronous
        this.prevParentId = '';     // to copy previous parent data to current
        this.selectedImageId = '';


        // initialize state
        /**@type {State} */
        this.state = this.initState();

    }

    /** @type {Errors}  */
    errors = { name: '', parent: '', sameColor: '', title: '', content: '' };  // local object to track errors and save to state as needed

    
    componentDidMount() {
        // log ('Edit Category did mount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.selectedImageId !== PageManager.getImageId()) {
            this.selectedImageId = PageManager.getImageId();
            this.setState({fields: {...this.state.fields, image: this.selectedImageId}});
        }
    }

    render() {
        return (
            <>
                <EditCategoryNavbar
                    currCategoryId={this.currCategoryId}
                    onClickBack={this.onClickBack}
                    onClickSave={this.onClickSave}
                    onClickSaveNew={this.onClickSaveNew}
                    onClickDelete={this.onClickDelete}
                />
                <EditCategoryView
                    page={this.state}
                    onToggleSection={this.onToggleSection}
                    onInputChange={this.onInputChange}
                    onImageChange={this.onImageChange}
                    onClickAddImage={this.onClickAddImage}
                    onClickRemoveImage={this.onClickRemoveImage}
                    onSelectFontColor={this.onSelectFontColor}
                    onSelectBackgroundColor={this.onSelectBackgroundColor}
                    onSelectCategory = {this.onSelectCategory}
                />
            </>
        )
    }

    // ************* handle form events

    onClickBack = () => {
        // this.reset();
        PageManager.goBack();
    }

    onClickSave = () => {
        Utils.debug('saving category...');
        try {
            this.validate();
            if ( this.hasError() ) {
                Notification.snackBarError(texts.validationError);
            } else {
                if ( this.hasChanged() ) {
                    Notification.snackBarInfo(texts.save);
                    this.save();    
                }
                PageManager.goBack();
            }                        
        } catch (error) {
            Utils.error('Error saving category!', error.message);
            Notification.snackBarError(`${texts.saveError} ${error.message}`, 10);            
        }

    }

    onClickSaveNew = () => {
        Utils.debug('saving/creating category...');
        try {
            this.validate();
            if ( this.hasError() ) {
                Notification.snackBarError(texts.validationError);
            } else {
                Notification.snackBarInfo(texts.saveNew);
                if ( this.hasChanged() ) {
                    this.save();
                }
                this.reset(); // so it won't copy the passed in category
                this.setState(this.initState(), () => this.updateStyling(this.prevParentId) );
            }                
        } catch (error) {
            Utils.error('Error saving/creating category!', error.message);
            Notification.snackBarError(`${texts.saveError} ${error.message}`, 10);            
        }
    }

    onClickDelete = () => {
        Utils.debug('deleting category...')
        try {
            if (this.state.isNew) {
                PageManager.goBack();
            } else {
                Notification.dialog(
                    texts.deleteWarning.title, 
                    texts.deleteWarning.content,
                    this.delete,  // pass callback when confirm delete
                    NotificationType.WARN);
            }                
        } catch (error) {
            Utils.error('Error deleting category!', error.message);
            Notification.snackBarError(`${texts.deleteError} ${error.message}`, 10);           
        }
    }

    /** @param {string} section */
    onToggleSection = (section) => {
        switch (section) {
            case Section.DESCRIPTION:
                this.setState({ expanded: { ...this.state.expanded, description: !this.state.expanded.description } });
                break;
            case Section.STYLING:
                this.setState({ expanded: { ...this.state.expanded, styling: !this.state.expanded.styling } });
                break;
            default:
                break;
        }
    }

    // ******* map form fields to state

    /** @param {import('react').SyntheticEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>} e */
    onInputChange = e => {
        if (e.currentTarget.name === 'parentCategoryId') {
            this.updateStyling(e.currentTarget.value);
        } else {
            const fields = Object.assign({}, this.state.fields);
            fields[e.currentTarget.name] = e.currentTarget.value;   // use currentTarget instead of target object
            this.setState({ fields }, this.postChange);    
        }
    }

    onImageChange = e => {
      const fields = Object.assign({}, this.state.fields);
      const prevEmoji = fields[e.currentTarget.name];
      let emoji = e.currentTarget.value;
  
      if (prevEmoji !== emoji) {
        emoji = emoji.replace(prevEmoji, ""); // remove last emoji, only 1 emoji allowed
      }
      const allChars = /^[ -~]+$/;
      if (allChars.test(emoji)) return; // don't allow regular characters
  
      fields[e.currentTarget.name] = emoji;
      this.setState({ fields }, this.postChange);
    }
  
      postChange = () => {        
        // update errors as user updates fields
        if ( this.hasError() ) {
            this.validate();
        }
    }

    /**
     * copy styling of parent to this category
     * @param {string} parentId 
     */
    updateStyling = (parentId) => {
        this.prevParentId = parentId;
        const parent = CategoryManager.getCategory(parentId);
        if (!isEmpty(parent) ) {
            this.setState( {fields: {...this.state.fields, 
                parentCategoryId:parentId,
                fontColor:parent.fontColor, 
                backgroundColor:parent.backgroundColor, 
                image: parent.image} }, this.postChange)
        }
    }

    /** @param {string} code */
    onSelectCategory = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, category: false } })
            return;
        }
        const changeCode = isEmpty(code) ? this.state.fields.parentCategoryId : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, category: !this.state.showDropdown.category },
            fields: { ...this.state.fields, parentCategoryId: changeCode },
        })
    }
    
    /** @param {string} code */
    onSelectFontColor = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, fontColor: false } })  // hide the dropdown onblur event
            return;
        }
        const changeCode = isEmpty(code) ? this.state.fields.fontColor : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, fontColor: !this.state.showDropdown.fontColor },
            fields: { ...this.state.fields, fontColor: changeCode },
        })
    }

    /** @param {string} code */
    onSelectBackgroundColor = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, bgColor: false } })  // hide the dropdown onblur event
            return;
        }
        const changeCode = isEmpty(code) ? this.state.fields.backgroundColor : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, bgColor: !this.state.showDropdown.bgColor },
            fields: { ...this.state.fields, backgroundColor: changeCode },
        })
    }


    onClickAddImage = () => {
        PageManager.goToPage(page.SEARCH_ICON, page.EDIT_CATEGORY);
    }


    onClickRemoveImage = () => {
        this.selectedImageId='';
        PageManager.clearImageId();
        this.setState({fields: {...this.state.fields, image:''}});
    }
    // ************* validations

    validate = () => {
        this.resetErrors()

        // empty name
        if ( isEmpty(this.state.fields.name) ) {
            this.errors.name = texts.emptyNameError;
        }
        // ROOT not allowed
        if ( this.state.fields.name.toUpperCase() === TaskConstants.Category.ROOT.toUpperCase() ) {
            this.errors.name = texts.rootNameError;
        }
        // empty parent
        if ( isEmpty(this.state.fields.parentCategoryId) ) {
            this.errors.parent = texts.emptyParentError;
        }
        // same color
        if (
            ( !isEmpty(this.state.fields.fontColor) && !isEmpty(this.state.fields.backgroundColor) ) &&
            ( this.state.fields.fontColor === this.state.fields.backgroundColor )
            ) {
            this.errors.sameColor = texts.sameColorError;
        }
        this.setState({ errors: this.errors });
    }

    resetErrors = () => {
        this.errors.name = '';
        this.errors.parent = '';
        this.errors.sameColor = '';
        this.errors.title = '';
        this.errors.content = '';
    }

    /** @returns {boolean} */
    hasError = () => {
        if (!isEmpty(this.errors.name)) return true;
        if (!isEmpty(this.errors.parent)) return true;
        if (!isEmpty(this.errors.sameColor)) return true;
        if (!isEmpty(this.errors.title)) return true;
        if (!isEmpty(this.errors.content)) return true;
        return false;
    }


    // ******************************* save

    save = () => {
        Utils.debug('saving category...');
        try {
            const category = this.stateToCategory(this.state);
            CategoryManager.upsertCategory(category);
        } catch (error) {
            Utils.error('Error saving category!', error.message);
            Notification.snackBarError(`${texts.saveError} ${error.message}`, 10);
        }
    }

    /**
     * copy state to category
     * @param {State} currState
     * @return {TaskCategory}
     */
    stateToCategory = (currState) => {
        /**@type {TaskCategory} */ let category = null;
        if (currState.isNew) {
            category = new TaskCategory();
        } else {
            category = this.origTaskCategory;
        }
        if (isEmpty(category)) {
            Utils.warn('Cannot copy state to category, category is empty!');
            return null;
        }
        category.categoryId = currState.fields.categoryId;
        category.parentCategoryId = currState.fields.parentCategoryId;
        category.name = currState.fields.name;
        category.image = currState.fields.image;
        category.backgroundColor = currState.fields.backgroundColor;
        category.fontColor = currState.fields.fontColor;
        return category;
    }

    // *************************** delete
    /**@param {string} ans */
    delete = (ans) => {
        try {
            if (Utils.isEmpty(ans)) return;
            if (ans.toLowerCase() !== 'yes') return;
    
            Notification.snackBarInfo(texts.delete);
            CategoryManager.deleteCategory(this.state.fields.categoryId);
            PageManager.goBack();                
        } catch (error) {
            Utils.error('Error in deleting category! ', error.message);
            Notification.snackBarError(`${texts.deleteError} ${error.message}`, 10);            
        }
    }

    /**
     * @return {boolean}
     */
    hasChanged = () => {
        const currStateFields = JSON.stringify(this.state.fields);
        return (this.origStateFields !== currStateFields)
    }




    // ************************* initialization

    /** @return {TaskCategory} */
    getTaskCategory = () => {
        if (isEmpty(this.currCategoryId)) return null;

        const taskCategory = CategoryManager.getCategory(this.currCategoryId);
        return taskCategory;
    }


    /** @return {State} */
    initState = () => {
        try {
            this.origTaskCategory = this.getTaskCategory();
            const baseState = new State();
    
            if ( !isEmpty(this.origTaskCategory) ) {
                baseState.isNew = false;
                this.categoryToState(this.origTaskCategory,baseState);
            } else {
                baseState.isNew = true;
            }
    
            baseState.categoryList = this.getCategoryList(this.origTaskCategory);
            if (baseState.isNew && !isEmpty(baseState.categoryList)) {
                baseState.fields.parentCategoryId = baseState.categoryList[0].id; // set parent category to the first item in the list - the ROOT category
            }
            if (baseState.isNew && !isEmpty(this.prevParentId) ) {
                baseState.fields.parentCategoryId = this.prevParentId; // copy previous parent category for save & new
            }
            this.prevParentId = baseState.fields.parentCategoryId;
    
            this.saveOrigState(baseState);
    
            return baseState;            
        } catch (error) {
            Utils.error('Error in initializing state ', error.message);
            Notification.snackBarError('Error in initializing state!');       
            return new State();     
        }

    }

    reset = () => {
        PageManager.clearCategoryId(); // clear the passed id
        this.currCategoryId = ''; // clear the local id
        this.origTaskCategory = null;
        PageManager.setImageId('');
        this.selectedImageId = '';
    }

    /**@param {State} baseState */
    saveOrigState = (baseState) => {
        this.origStateFields = JSON.stringify(baseState.fields);  // save the state for diff later
    }

    /** 
     * @param {TaskCategory} category
     * @return {Category[]} 
     */
    getCategoryList = (category) => {

        const /**@type {Category} */rootCategory = { id: TaskConstants.Category.ROOT, name: TaskConstants.Category.ROOT };

        const /**@type {TaskCategory[]} */ parentCategories = DataUtil.getCategoriesHierarchy(); //CategoryManager.getAll();
        let /**@type {string[]} */ descendantIds = [];
        if (!isEmpty(category)) {
            descendantIds = DataUtil.getDescendants(category).map((d) => d.categoryId)
        }

        // convert each category to {id,name} object
        const /**@type {Category[]} */ categories = parentCategories.filter((p) => {
            if (isEmpty(category)) return true;
            if (category.categoryId === p.categoryId) return false; // don't include self
            if (descendantIds.indexOf(p.categoryId) >= 0) return false; // don't include descendants
            return true;
        }).map((c) => {
            const /**@type {Category} */ cat = new Category();
            cat.id = c.categoryId;
            cat.name = ViewHelper.getCategoryDisplayText(c);  
            return cat;
        })

        categories.unshift(rootCategory);  // put ROOT category at the beginning

        return categories;
    }

        /**
     * copy taskcell to state
     * @param {TaskCategory} category
     * @param {State} baseState
     */
    categoryToState = (category, baseState)  => {
        if (isEmpty(category)) {
            Utils.warn('Cannot copy category to state, category is empty...');
        }
        baseState.fields.categoryId     = category.categoryId;
        baseState.fields.parentCategoryId = category.parentCategoryId;
        baseState.fields.name       = category.name;
        baseState.fields.image     = category.image;
        baseState.fields.fontColor   = category.fontColor;
        baseState.fields.backgroundColor = category.backgroundColor;
    }


}