import * as TaskConstants from '../util/TaskConstants'


export class TaskCell {
        /**@type {string} */ cellType = 'TASK_CELL';
        /**@type {string} */ taskId = '';
        /**@type {string} */ name = '';
        /**@type {string} */ categoryId = '';
        /**@type {string} */ status = TaskConstants.Status.NOT_STARTED;
        /**@type {string} */ importance = TaskConstants.Importance.NORMAL;
        /**@type {string} */ priority = TaskConstants.Priority.NORMAL;
        /**@type {string} */ startDate = '';
        /**@type {string} */ endDate = '';
        /**@type {string} */ repeat = TaskConstants.RepeatSelector.NONE;
        /**@type {string} */ notes = '';
        /**@type {string[]} */    labels = [];  // convert inputs to set before setting back to array

    
}


// categoryId, name, status, importance, priority, startDate, endDate, repeat, notes, labels[]