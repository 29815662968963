import React from 'react';

import GroupCard from '../../components/groupCard/GroupCard';

import gStyles from '../../App.module.css';     // global styles
import pStyles from './ByLabel.module.css';    // page-specific styles
import gTexts from '../../resources/texts'
import '../../resources/sprites.css'
import * as icon from '../../util/IconConstants'

import { isEmpty } from '../../util/Utils';
import { CategoryLevel, GroupLabel, TaskLevel } from './ViewData'
import * as TaskConstants from '../../util/TaskConstants'
import * as ViewHelper from '../../util/ViewHelper'

CategoryLevel.toString();    // suppress warning
GroupLabel.toString();    // suppress warning
TaskLevel.toString();    // suppress warning

/* 
    Presentation component for Label View Page
    - should be stateless

    by Ludwin 2019/11/17
*/


/**
 * @typedef {Object} Props
 * @property {boolean} isPageVertical
 * @property {Map} allGroups
 */


/** @param {Props} props */
export const ByLabelView = (props) => (
    <main className={[
            props.isPageVertical ? gStyles.colFlex : gStyles.horizontalScroll,
            ].join(' ')}>

        {/* loop through hierarchies */}

        {props.allGroups.size > 0 &&
            <div>
                {buildGroup(props)}
            </div>
        }

    </main>
)

/**
 * build Group Level component
 * @param {Props} props 
 */
 const buildGroup = (props) => {
  const groups = Array.from(props.allGroups.values());
  const groupView = groups.map((g, i) => (
    <GroupCard key={i} group={g} isPageVertical={props.isPageVertical} />
  ))
  return groupView;
}
