import React from 'react'

import '../../resources/sprites2.css'
import * as icon from '../../util/IconConstants'
import * as page from '../../util/PageConstants'
import { goToPage, goBack } from '../../navigation/PageManager'

import layout from './layout.module.css';
import gStyles from '../../App.module.css';

function Footer({ showNew = true, onNew = () => goToPage(page.EDIT_TASK) }) {
  return (
    <div className={layout.footer}>

      <button className={[gStyles.btnIconFilled, icon.BACK,].join(' ')} onClick={() => goBack()} ></button>

      {showNew && <button onClick={onNew} className={[gStyles.btnIconFilled, icon.NEW_TASK,].join(' ')} ></button>}

    </div>
  )
}

export default Footer;