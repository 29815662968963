import React from 'react';

import Header from '../../components/layout/header'
import Footer from './homeFooter'

import {HomeHeader} from './HomeHeader';
import {HomeView, Section} from './HomeView';
import * as PageManager from '../../navigation/PageManager';
import * as page from '../../util/PageConstants';
import { goToPage } from '../../navigation/PageManager';
import { Expanded } from './HomeState';

import gTexts from '../../resources/texts';
import gStyles from '../../styles/global.module.css';

/**
 * Home Page
 * - container component
 */
export default class Home extends React.Component {

    // initialize expanded sections
    state = {
        expanded: new Expanded(),
    }

    componentDidMount() {
        // log ('Home did mount');
    }
    
    render() {
      return (
        <div className={gStyles.pageBox}>
          <div className={gStyles.headerBox}>
            <Header title={gTexts.appTitle} currPage={page.HOME} />
          </div>
          <div className={gStyles.bodyBox}>
          <HomeView
                expanded                = {this.state.expanded}
                onClickCreate           = {this.onClickCreate}
                onClickSearch           = {this.onClickSearch}
                onClickByCategory       = {this.onClickByCategory}
                onClickByHotTask        = {this.onClickByHotTask}
                onClickByPriority       = {this.onClickByPriority}
                onClickByImportance     = {this.onClickByImportance}
                onClickByStatus         = {this.onClickByStatus}
                onClickByDate           = {this.onClickByDate}
                onClickByLabel          = {this.onClickByLabel}
                onClickGotoApps          = {this.onClickGotoApps}
                onClickSetupCategories  = {this.onClickSetupCategories}
                onClickSetupLabels      = {this.onClickSetupLabels}
                onClickSettings         = {this.onClickSettings}
                onClickAbout            = {this.onClickAbout}
                onClickHowToUse         = {this.onClickHowToUse}
                onClickTipsTricks       = {this.onClickTipsTricks}
                onToggleSection         = {this.onToggleSection}
            />

          </div>
          <div className={gStyles.footerBox}>
            <Footer onNew={this.onClickCreate} />
          </div>

        </div>
      )    

    }

    onClickCreate = () => {
        PageManager.goToTaskPage(page.EDIT_TASK, page.HOME) ;
    }
    
    onClickSearch = () => {
        PageManager.goToPage(page.SEARCH);
    }
    onClickByCategory = () => {
        PageManager.goToPage(page.VIEW_BY_CATEGORY);
    }
    onClickByHotTask = () => {
        PageManager.goToPage(page.VIEW_BY_HOT_TASKS);
    }
    onClickByPriority = () => {
        PageManager.goToPage(page.VIEW_BY_PRIORITY);
    }
    onClickByImportance = () => {
        PageManager.goToPage(page.VIEW_BY_IMPORTANCE);
    }
    onClickByStatus = () => {
        PageManager.goToPage(page.VIEW_BY_STATUS);
    }
    onClickByDate = () => {
        PageManager.goToPage(page.VIEW_BY_DATE);
    }
    onClickByLabel = () => {
        PageManager.goToPage(page.VIEW_BY_LABEL);
    }
    onClickGotoApps = () => {
        window.location.href = page.LIST_APP;
    }
    onClickSetupCategories = () => {
        PageManager.goToPage(page.SETUP_CATEGORY, page.HOME);
    }
    onClickSetupLabels = () => {
        PageManager.goToPage(page.SETUP_LABEL);
    }
    onClickSettings = () => {
        goToPage(page.SYSTEM)
    }
    onClickAbout = () => {
        goToPage(page.ABOUT);
    }
    onClickHowToUse = () => {
        goToPage(page.HOW_TO)
    }
    onClickTipsTricks = () => {
        goToPage(page.TIPS_TRICKS)
    }

    /**@param {string} section */
    onToggleSection = (section) => {
        switch (section) {
            case Section.CREATE:
                this.setState( {expanded: {...this.state.expanded, create:!this.state.expanded.create} });
                break;
            case Section.SEARCH:
                this.setState( {expanded: {...this.state.expanded, search:!this.state.expanded.search} });
                break;
            case Section.VIEW:
                this.setState( {expanded: {...this.state.expanded, view:!this.state.expanded.view} });
                break;
            case Section.APPS:
                this.setState( {expanded: {...this.state.expanded, apps:!this.state.expanded.apps} });
                break;
            case Section.SETUP:
                this.setState( {expanded: {...this.state.expanded, setup:!this.state.expanded.setup} });
                break;
            case Section.INFO:
                this.setState( {expanded: {...this.state.expanded, info:!this.state.expanded.info} });
                break;
            default:
                break;
        }
    }

}

