import React from 'react';

import gStyles from '../../App.module.css';     // global styles
import pStyles from './EditTask.module.css';    // page-specific styles
import gTexts from '../../resources/texts'
import texts from './EditTaskTexts';
import '../../resources/sprites.css'
import * as icon from '../../util/IconConstants'
import * as size from '../../util/SizeConstants'
import * as TaskConstants from '../../util/TaskConstants'

import { isEmpty } from '../../util/Utils';

import {State} from './TaskState';
import { SelectOption } from '../../components/dropDown/SelectProps';
import {SelectCategory, SelectList, SelectFit} from '../../components/dropDown/Dropdown'
import * as ViewHelper from '../../util/ViewHelper'
import { FieldInfo } from '../../components/fieldInfo/FieldInfo';
import dayjs from 'dayjs';
import { Calendar} from '../../components/calendar/Calendar';

State.toString();    // suppress warning

/* 
    Presentation component for Edit Task Page
    - should be stateless
*/

export const Section = {
    DESCRIPTION: 'DESCRIPTION',
    DETAILS: 'DETAILS',
    DUE_DATES: 'DUE_DATES',
    LABELS: 'LABELS',
}

// should match those in the form state for proper mapping
const fieldName = {
    name: 'name',
    category: 'categoryId',
    status: 'status',
    priority: 'priority',
    importance: 'importance',
    notes: 'notes',
    startDate: 'startDate',
    endDate: 'endDate',
    repeat: 'repeat',
    labels: 'labels',
}

/**
 * @typedef {Object} Props
 * @property {State} page
 * @property {(section:string)=>void} onToggleSection
 * @property {(e:import('react').SyntheticEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>)=>void} onInputChange
 * @property { ()=>void} onCreateCategory
 * @property { ()=>void} onCreateLabel
 * @property { (code:string)=>void} onSelectCategory
 * @property { (code:string)=>void} onSelectStatus
 * @property { (code:string)=>void} onSelectPriority
 * @property { (code:string)=>void} onSelectImportance
 * @property { (code:string)=>void} onSelectStartDate
 * @property { (code:string)=>void} onSelectEndDate
 * @property { (currDate:string)=>void} onClickStartDate
 * @property { (currDate:string)=>void} onClickEndDate
 * @property { (code:string)=>void} onSelectRepeat
 * @property { (code:string)=>void} onClickLabel
 * @property { (field:string)=>void} onShowInfo
 * @property { ()=>void} onCloseInfo
 */

 /** @param {Props} props */
export const EditTaskView = (props) => (
    <main className={[gStyles.colFlex, gStyles.form].join(' ')}>
        {/* description */}
        <section className={[gStyles.colFlex, gStyles.formSection].join(' ')}>
            <div className={[gStyles.rowNowrapFlex, gStyles.formSectionHeader].join(' ')} onClick={() => props.onToggleSection(Section.DESCRIPTION)}>
                <span>{texts.descriptionHeader}</span>
                <button className={[
                    gStyles.splitRight, 
                    props.page.expanded.description ? icon.COLLAPSE_WHITE : icon.EXPAND_WHITE, 
                    gStyles.btnIcon].join(' ')}>
                </button>
            </div>
            <div className={[gStyles.colFlex, gStyles.formContent].join(' ')} style={{ display: props.page.expanded.description ? '' : 'none' }}>
                {/* name */}
                <div className={[gStyles.colFlex, gStyles.formField].join(' ')}>
                    <div className={gStyles.fieldLabel}>{texts.nameLabel}</div>
                    <input type='text' placeholder={texts.nameHint} className={gStyles.inputField} 
                        value={props.page.fields.name} name={fieldName.name} onChange={props.onInputChange} autoComplete='off'>
                    </input>
                    <div className={props.page.errors.name ? gStyles.errorField : gStyles.hide} >{props.page.errors.name}</div>
                </div>
                {/* category */}
                <div className={[gStyles.colFlex, gStyles.formField].join(' ')}>
                    {/* will close the field info when loses focus (click outside) */}
                    <div onBlur={props.onCloseInfo} tabIndex={0} 
                        className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                        <div className={[gStyles.rowFlex].join(' ')} onClick={() => props.onShowInfo(texts.categoryLabel)}>
                            <span>{texts.categoryLabel}</span>
                            <button className={[icon.INFO, gStyles.btnIcon].join(' ')} ></button>
                            
                        </div>
                        <button 
                            title={gTexts.setupCategoryTip}
                            onClick={props.onCreateCategory}
                            className={[gStyles.splitRight, icon.LIST, gStyles.btnIcon].join(' ')}></button>
                        
                    </div>
                    <FieldInfo text={texts.categoryInfo.content} isShow={props.page.showInfo.category}/>
                    {!isEmpty(props.page.categoryList) &&
                        <SelectCategory
                            categoryId={props.page.fields.categoryId}
                            categories={props.page.categoryList}
                            onSelect ={props.onSelectCategory}
                            isShow={props.page.showDropdown.category}
                        />
                    }
                    <div className={props.page.errors.category ? gStyles.errorField : gStyles.hide} >{props.page.errors.category}</div>
                </div>
                {/* status */}
                <div className={gStyles.formField}>
                    <div onBlur={props.onCloseInfo} tabIndex={0} 
                        className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                        <div className={[gStyles.rowFlex].join(' ')} onClick={() => props.onShowInfo(texts.statusLabel)}>
                            <span>{texts.statusLabel}</span>
                            <button className={[icon.INFO, gStyles.btnIcon].join(' ')} ></button>
                        </div>
                    </div>
                    <FieldInfo text={texts.statusInfo.content} isShow={props.page.showInfo.status} />
                    <SelectList
                        selectedOption={ new SelectOption(props.page.fields.status, ViewHelper.getStatusDescription(props.page.fields.status))}
                        selectOptions={[
                            new SelectOption(TaskConstants.Status.NOT_STARTED, gTexts.statusNotStarted),
                            new SelectOption(TaskConstants.Status.IN_PROGRESS, gTexts.statusInProgress),
                            new SelectOption(TaskConstants.Status.DONE, gTexts.statusDone),
                            new SelectOption(TaskConstants.Status.CANCELLED, gTexts.statusCancelled),
                            new SelectOption(TaskConstants.Status.WAITING, gTexts.statusWaiting),
                        ]}
                        isShow={props.page.showDropdown.status}
                        onSelect={props.onSelectStatus}
                    />

                </div>
                {/* priority */}
                <div className={gStyles.formField}>
                    <div onBlur={props.onCloseInfo} tabIndex={0} 
                        className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                        <div className={[gStyles.rowFlex].join(' ')} onClick={() => props.onShowInfo(texts.priorityLabel)}>
                            <span>{texts.priorityLabel}</span>
                            <button className={[icon.INFO, gStyles.btnIcon].join(' ')} ></button>
                        </div>
                    </div>
                    <FieldInfo text={texts.priorityInfo.content} isShow={props.page.showInfo.priority} />
                    <SelectList
                        selectedOption={new SelectOption(props.page.fields.priority, ViewHelper.getPriorityDescription(props.page.fields.priority))}
                        selectOptions={[
                            new SelectOption(TaskConstants.Priority.VERY_HIGH, gTexts.priorityVeryHigh),
                            new SelectOption(TaskConstants.Priority.HIGH, gTexts.priorityHigh),
                            new SelectOption(TaskConstants.Priority.NORMAL, gTexts.priorityNormal),
                            new SelectOption(TaskConstants.Priority.LOW, gTexts.priorityLow),
                            new SelectOption(TaskConstants.Priority.VERY_LOW, gTexts.priorityVeryLow),
                        ]}
                        isShow={props.page.showDropdown.priority}
                        onSelect={props.onSelectPriority}
                    />
                </div>

                {/* importance */}
                <div className={gStyles.formField}>
                    <div onBlur={props.onCloseInfo} tabIndex={0} 
                        className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                        <div className={[gStyles.rowFlex].join(' ')} onClick={() => props.onShowInfo(texts.importanceLabel)}>
                            <span>{texts.importanceLabel}</span>
                            <button className={[icon.INFO, gStyles.btnIcon].join(' ')} ></button>
                        </div>
                    </div>
                    <FieldInfo text={texts.importanceInfo.content} isShow={props.page.showInfo.importance} />
                    <SelectList
                        selectedOption={new SelectOption(props.page.fields.importance, ViewHelper.getImportanceDescription(props.page.fields.importance))}
                        selectOptions={[
                            new SelectOption(TaskConstants.Importance.VERY_HIGH, gTexts.importanceVeryHigh),
                            new SelectOption(TaskConstants.Importance.HIGH, gTexts.importanceHigh),
                            new SelectOption(TaskConstants.Importance.NORMAL, gTexts.importanceNormal),
                            new SelectOption(TaskConstants.Importance.LOW, gTexts.importanceLow),
                            new SelectOption(TaskConstants.Importance.VERY_LOW, gTexts.importanceVeryLow),
                        ]}
                        isShow={props.page.showDropdown.importance}
                        onSelect={props.onSelectImportance}
                    />
                </div>
                
            </div>
        </section>

        {/* details */}
        <section className={[gStyles.colFlex, gStyles.formSection].join(' ')} >
            <div className={[gStyles.rowNowrapFlex, gStyles.formSectionHeader].join(' ')} onClick={() => props.onToggleSection(Section.DETAILS)}>
                <span>{texts.detailsHeader}</span>
                <button className={[
                    gStyles.splitRight, 
                    props.page.expanded.details ? icon.COLLAPSE_WHITE : icon.EXPAND_WHITE, 
                    gStyles.btnIcon].join(' ')}></button>
            </div>
            <div className={[gStyles.colFlex, gStyles.formContent].join(' ')} style={{ display: props.page.expanded.details ? 'block' : 'none' }}>
                <div className={[gStyles.colFlex].join(' ')}>
                    <div className={gStyles.fieldLabel}>{texts.notesLabel}</div>
                    <textarea 
                        placeholder={texts.notesHint} 
                        className={gStyles.textAreaField} 
                        rows={5} maxLength={size.MAX_TEXT}
                        value={props.page.fields.notes} name={fieldName.notes} onChange={props.onInputChange}>
                    </textarea>
                </div>
            </div>
        </section>

        {/* due dates */}
        <section className={[gStyles.colFlex, gStyles.formSection].join(' ')} >
            <div className={[gStyles.rowNowrapFlex, gStyles.formSectionHeader].join(' ')} onClick={() => props.onToggleSection(Section.DUE_DATES)}>
                <span>{texts.dueDatesHeader}</span>
                <button className={[
                    gStyles.splitRight, 
                    props.page.expanded.dueDates ? icon.COLLAPSE_WHITE : icon.EXPAND_WHITE, 
                    gStyles.btnIcon].join(' ')}></button>
            </div>
            <div className={[gStyles.colFlex, gStyles.formContent].join(' ')} style={{ display: props.page.expanded.dueDates ? 'block' : 'none' }}>
                {/* Start date */}
                <div className={[gStyles.colFlex,gStyles.formField].join(' ')}>
                    <div onBlur={props.onCloseInfo} tabIndex={0} 
                        className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                        <div className={[gStyles.rowFlex].join(' ')} onClick={() => props.onShowInfo(texts.startDateLabel)}>
                            <span>{texts.startDateLabel}</span>
                            <button className={[icon.INFO, gStyles.btnIcon].join(' ')} ></button>
                        </div>
                    </div>
                    <FieldInfo text={texts.startDateInfo.content} isShow={props.page.showInfo.startDate} />

                    <div className={[gStyles.rowFlex, pStyles.dateFieldGroup].join(' ')}>

                        {/* date selector */}
                        <SelectFit
                            selectedOption={new SelectOption(props.page.dateSelector.startDate, ViewHelper.getDateSelectorDescription(props.page.dateSelector.startDate))}
                            selectOptions={[
                                new SelectOption(TaskConstants.DateSelector.NONE, gTexts.dateSelectorNone),
                                new SelectOption(TaskConstants.DateSelector.TODAY, gTexts.dateSelectorToday),
                                new SelectOption(TaskConstants.DateSelector.TOMORROW, gTexts.dateSelectorTomorrow),
                                new SelectOption(TaskConstants.DateSelector.WEEK1, gTexts.dateSelectorWeek1),
                                new SelectOption(TaskConstants.DateSelector.WEEK2, gTexts.dateSelectorWeek2),
                                new SelectOption(TaskConstants.DateSelector.MONTH1, gTexts.dateSelectorMonth1),
                                new SelectOption(TaskConstants.DateSelector.DATE, gTexts.dateSelectorDate),    
                            ]}
                            isShow={props.page.showDropdown.startDate}
                            onSelect={props.onSelectStartDate}
                        />

                        {/* date input */}
                        <div 
                            className={[gStyles.splitRight, pStyles.dateField].join(' ')} 
                            onClick={() => props.onClickStartDate(props.page.fields.startDate)}
                            >
                            <input type='text' readOnly={true}
                                className={[pStyles.dateInput].join(' ')} 
                                value={
                                    dayjs(props.page.fields.startDate).isValid() ?
                                    dayjs(props.page.fields.startDate).format('YYYY-MMM-DD ddd') :
                                    ''
                                }
                                name={fieldName.startDate}
                            ></input>
                            <button 
                                title={gTexts.calendarTip}
                                className={[icon.CALENDAR, gStyles.btnIcon].join(' ')}></button>
                        </div>

                    </div>
                    <div className={props.page.errors.startDate ? gStyles.errorField : gStyles.hide} >{props.page.errors.startDate}</div>
                </div>

                {/* calendar shows up only when there's a date */}
                <div className={pStyles.calendarContainer}>
                    { Calendar.isShowCalendar(fieldName.startDate) && <Calendar /> }
                </div>

                {/* end date */}
                <div className={gStyles.formField}>
                    <div onBlur={props.onCloseInfo} tabIndex={0} 
                        className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                        <div className={[gStyles.rowFlex].join(' ')} onClick={() => props.onShowInfo(texts.endDateLabel)}>
                            <span>{texts.endDateLabel}</span>
                            <button className={[icon.INFO, gStyles.btnIcon].join(' ')} ></button>
                        </div>
                    </div>
                    <FieldInfo text={texts.endDateInfo.content} isShow={props.page.showInfo.endDate} />

                    <div className={[gStyles.rowFlex, pStyles.dateFieldGroup].join(' ')}>

                        <SelectFit
                            selectedOption={new SelectOption(props.page.dateSelector.endDate, ViewHelper.getDateSelectorDescription(props.page.dateSelector.endDate))}
                            selectOptions={[
                                new SelectOption(TaskConstants.DateSelector.NONE, gTexts.dateSelectorNone),
                                new SelectOption(TaskConstants.DateSelector.TODAY, gTexts.dateSelectorToday),
                                new SelectOption(TaskConstants.DateSelector.TOMORROW, gTexts.dateSelectorTomorrow),
                                new SelectOption(TaskConstants.DateSelector.WEEK1, gTexts.dateSelectorWeek1),
                                new SelectOption(TaskConstants.DateSelector.WEEK2, gTexts.dateSelectorWeek2),
                                new SelectOption(TaskConstants.DateSelector.MONTH1, gTexts.dateSelectorMonth1),
                                new SelectOption(TaskConstants.DateSelector.DATE, gTexts.dateSelectorDate),    
                            ]}
                            isShow={props.page.showDropdown.endDate}
                            onSelect={props.onSelectEndDate}
                        />

                        <div
                            className={[gStyles.splitRight, pStyles.dateField].join(' ')}
                            onClick={() => props.onClickEndDate(props.page.fields.endDate)}
                        >

                            <input type='text' readOnly={true}
                                className={[pStyles.dateInput].join(' ')}
                                value={
                                    dayjs(props.page.fields.endDate).isValid() ?
                                    dayjs(props.page.fields.endDate).format('YYYY-MMM-DD ddd') :
                                    ''
                                }
                                name={fieldName.endDate}
                            ></input>
                            <button 
                                title={gTexts.calendarTip}
                                className={[icon.CALENDAR, gStyles.btnIcon].join(' ')}></button>
                        </div>

                    </div>
                    <div className={props.page.errors.endDate ? gStyles.errorField : gStyles.hide} >{props.page.errors.endDate}</div>
                </div>


                {/* calendar shows up only when there's a date */}
                <div className={pStyles.calendarContainer}>
                    {Calendar.isShowCalendar(fieldName.endDate) && <Calendar /> }                    
                </div>

                {/* repeat */}
                <div className={gStyles.formField}>
                    {/* will close the field info when loses focus (click outside) */}
                    <div onBlur={props.onCloseInfo} tabIndex={0} 
                        className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                        <div className={[gStyles.rowFlex].join(' ')} onClick={() => props.onShowInfo(texts.repeatLabel)}>
                            <span>{texts.repeatLabel}</span>
                            <button className={[icon.INFO, gStyles.btnIcon].join(' ')} ></button>
                        </div>
                    </div>
                    <FieldInfo text={texts.repeatInfo.content} isShow={props.page.showInfo.repeat} />
                    <SelectFit
                        selectedOption={new SelectOption(props.page.fields.repeat, ViewHelper.getRepeatDescription(props.page.fields.repeat))}
                        selectOptions={[
                            new SelectOption(TaskConstants.RepeatSelector.NONE, gTexts.repeatSelectorNone),
                            new SelectOption(TaskConstants.RepeatSelector.DAILY, gTexts.repeatSelectorDaily),
                            new SelectOption(TaskConstants.RepeatSelector.WEEKLY, gTexts.repeatSelectorWeekly),
                            new SelectOption(TaskConstants.RepeatSelector.MONTHLY, gTexts.repeatSelectorMonthly),
                            new SelectOption(TaskConstants.RepeatSelector.YEARLY, gTexts.repeatSelectorYearly),
                        ]}
                        isShow={props.page.showDropdown.repeat}
                        onSelect={props.onSelectRepeat}
                    />
                </div>
            </div>
        </section>

        {/* labels */}
        <section className={[gStyles.colFlex, gStyles.formSection].join(' ')} >
            <div className={[gStyles.rowNowrapFlex, gStyles.formSectionHeader].join(' ')} onClick={() => props.onToggleSection(Section.LABELS)}>
                <span>{texts.labelsHeader}</span>
                <button className={[
                    gStyles.splitRight, 
                    props.page.expanded.labels ? icon.COLLAPSE_WHITE : icon.EXPAND_WHITE, 
                    gStyles.btnIcon].join(' ')}></button>
            </div>
            <div className={[gStyles.colFlex, gStyles.formContent].join(' ')} style={{ display: props.page.expanded.labels ? 'block' : 'none' }}>
                <div className={gStyles.formField}>
                    <div onBlur={props.onCloseInfo} tabIndex={0} 
                        className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                        <div className={[gStyles.rowFlex].join(' ')} onClick={() => props.onShowInfo(texts.tagLabel)}>
                            <span>{texts.tagLabel}</span>
                            <button className={[icon.INFO, gStyles.btnIcon].join(' ')} ></button>
                        </div>
                        {/* add label */}
                        <button 
                            title={gTexts.setupLabelTip}
                            onClick={props.onCreateLabel}
                            className={[gStyles.splitRight, gStyles.splitRight, icon.LIST, gStyles.btnIcon].join(' ')}></button>
                    </div>
                    <FieldInfo text={texts.labelInfo.content} isShow={props.page.showInfo.label} />
                    {/* labels list */}
                    <div className={gStyles.colFlex}>
                        {
                            props.page.labelList.map( (l, i) => (
                            <div key={i} 
                                onClick={()=>props.onClickLabel(l.id)}
                                className={[gStyles.rowNowrapFlex, pStyles.labelLine].join(' ')}>
                                <span className={l.isChecked ? icon.CHECKED_BOX : icon.UNCHECKED_BOX}></span>

                                <span style={{ paddingLeft: size.SMALL_SPACER }}>{l.name}</span>
                            </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    </main>
)


