/**
 * hack to import raw html into js
 * 
 * Tip: copy html code into .html file for easy editing and paste back here
 */

 export const rawHtml = `
 <style>
  .section {
    line-height: 22px;
    font-size: 14px;
  }

  .productName {
    font-weight: 500;
    color: darkslateblue;
  }

  .head1 {
    line-height: 28px;
    font-size: 20px;
  }

  .indent {
    padding-left: 16px;
  }

  .list {
    list-style-type: none;
  }

  .list li {
    padding-bottom: 4px;
  }

  .emphasis {
    font-style: italic;
  }

  .spacer {
    margin-top: 16px;
  }
</style>
<div class="section">
  <p>You can follow the guidelines below to get the most out of <span class="productName">MyBrainMate Task</span>
    and be more productive.
  <p class="head1">
    Using Categories and Sub-categories
  </p>
  <div class="indent">
    <p>Use category to group related tasks
    <p>It is useful in creating a mini-project, for example - Build your own computer. You can also use category in
      setting goals, like Learn new skill
    <p>Categories and sub-categories place your tasks in a hierarchy, it’s a way of organizing <span
        class="emphasis">vertically</span>. Each task can belong only to a single category
  </div>
  <p class="head1">
    Using Labels
  <div class="indent">
    <p>Label is also used to group related tasks. It’s a <span class="emphasis">horizontal</span> way of
      organizing your tasks. A task can have multiple labels
    <p>A label is a tag you attach to your tasks to identify their grouping
    <p>For example, use labels – $, $$, $$$ – to give your tasks a meaning of relative cost from cheap to expensive
    <p>You can also use labels to indicate difficulty of tasks, example – Easy, Hard
    <p>You may also use names (say Mommy, Diana, Sister) to indicate you delegate the task to them or the task is
      somehow related to them
    <p>You can also use places – Market, Shopping, Office – to remind you of what you will do in these places
  </div>
  <p class="head1">
    Use colors and visuals
  <div class="indent">
    <p>Our brain works better with images. Use colors to differentiate categories. Choose icons that best represents
      groups. This way it’s easier and faster to recognize your data at a glance.
    <p>Use cool colors (blue, green, indigo) for tasks that are for fun, relaxation, entertainment. Use warm colors for
      tasks that are active, busy, and high priority.
  </div>
  <p class="head1">
    Magic 7
  <div class="indent">
    <p>Keep your number of categories (and their levels) around the magic number of 7. If you're nearing 10-12, it's
      time to create a subcategory (this is also called chunking).
    <p>Same goes with tasks. If you have more than 10 tasks in a day, you’re probably overtasking yourself. Spread out
      your tasks to several days.
    <p>Also, small tasks are easier to manage and faster to complete. Ideally, tasks should be completed in 1-2 days.
  </div>
  <p class="head1">
    Relax
  <div class="indent">
    <p>Don't overdo it, too much organizing restricts you and puts yourself in a box.
    <p>Be flexible, create buffers for your tasks and schedules. Things will not always happen according to plan.
    <p>Don't overstuff your list, don't list everything.
    <p>Learn to recognize big things and small things, put emphasis on big things, learn to let go of small things.
    <p>Choose 3 highly important tasks (big rocks) to complete in a day.
    <p>Take it easy, you’re human not a robot.
    <p>You don't need to become a productive ninja, just productive.
  </div>
  <p class="head1">
    Work is work, Personal is personal
  <div class="indent">
    <p>Most likely, you already have a task management tool at work. Don’t use it to enter your personal tasks. You
      don’t have privacy to your data, it’s visible to your superiors.
    <p>Likewise, avoid entering work tasks to <span class="productName">MyBrainMate Task</span> to avoid duplicates
      and confusion.
    <p>Create a clear separation of your time and place - work zone, home zone, be sure not to mix them. Leave your work
      in the office and focus on personal stuffs when you’re at home. It helps in promoting balanced lifestyle.
    <p>Work to live, not the other way around.
  </div>
`