export default {

    viewTitle           : 'View by Status',

    // unused?
    statusNotStarted  : 'Not Started',
    importanceHigh      : 'In Progress',
    importanceNormal    : 'Done',
    importanceLow       : 'Waiting',
    importanceVeryLow   : 'Cancelled',


}