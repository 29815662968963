import React from 'react'
// import gStyles from '../../App.module.css';
import gStyles from '../../styles/global.module.css';

import texts from './AboutTexts'
import { goBack } from '../../navigation/PageManager'
import Header from '../../components/layout/header'
import Footer from '../../components/layout/footer'
import * as page from '../../util/PageConstants'

import { rawHtml } from './howto_html';

export default class HowTo extends React.Component {

    onBack = () => {
        goBack()
    }

    render() {

      return (
        <div className={gStyles.pageBox}>
          <div className={gStyles.headerBox}>
            <Header title={texts.howToTitle} currPage={page.HOW_TO} />
          </div>
          <div className={gStyles.bodyBox}>
            <main
                    dangerouslySetInnerHTML={{__html: rawHtml}}
            >
            </main>
  
          </div>
          <div className={gStyles.footerBox}>
            <Footer />
          </div>
  
        </div>
      )

    }

}