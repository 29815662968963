
export class Expanded {
    /**@type {boolean} */ create = true;
    /**@type {boolean} */ search = true;
    /**@type {boolean} */ view = true;
    /**@type {boolean} */ apps = true;
    /**@type {boolean} */ setup = false;
    /**@type {boolean} */ info = false;

}


