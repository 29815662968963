export default {

    viewTitle           : 'View by Importance',

    // unused?
    importanceVeryHigh  : 'Very High',
    importanceHigh      : 'High',
    importanceNormal    : 'Normal',
    importanceLow       : 'Low',
    importanceVeryLow   : 'Very Low',


}