import React, {useState, useEffect} from 'react';

import CategoryCard from '../../components/categoryCard/CategoryCard';

import gStyles from '../../App.module.css';     // global styles
import styles from './GroupCard.module.css';    // page-specific styles
import '../../resources/sprites.css'
import '../../resources/sprites2.css'
import * as icon from '../../util/IconConstants'

import { GroupLevel } from '../../pages/viewByCategory/ViewData';


/**
 * @param {Object} params
 * @param {GroupLevel} params.group
 * @param {boolean} params.isPageVertical
 * 
 */
const GroupCard = ({ group, isPageVertical }) => {
  const [isExpand, setExpand] = useState(true);

  useEffect(() => {
    setExpand(group.isExpanded); // expand/collapse all
  }, [group.isExpanded])

  /** @param {GroupLevel} group */
  const isShowBody = (group) => {
    if (isExpand) {
      const groups = Array.from(group.categoryMap.values());
      return groups.length;
    }
    return false;
  }

  return (
    <div className={[
      isPageVertical ? "" : gStyles.horizontalChild,
      styles.groupSection].join(' ')} >
      <div className={[gStyles.rowFlex, styles.groupHeader].join(' ')} onClick={() => setExpand(!isExpand)}>

        {/* name */}
        {group.groupDesc}

        {/* expand */}
        <button className={[
          isExpand ? icon.COLLAPSE : icon.EXPAND,
          gStyles.btnIcon,
        ].join(' ')}></button>
      </div>

      <div className={styles.groupBody} style={{ display: isShowBody(group) ? '' : 'none' }}>
        {buildCategory(group)}
      </div>

    </div>
  )

}

/**
 * build category level component
 * @param {GroupLevel} group 
 */
const buildCategory = (group) => {
  const categories = Array.from(group.categoryMap.values());
  const categoryView = categories.map((c, i) => (
    <CategoryCard key={i} category={c} />
  ))
  return categoryView;
}

export default GroupCard;