import React from 'react';
import * as DataManager from '../../data/TaskManager'

import { SearchNavbar } from './SearchNavBar';
import { SearchBar } from './SearchBar';
import { SearchView } from './SearchView'
import { SearchResult } from './SearchResult'

import { goToPage, goBack, goToTaskPage } from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import { TaskCell } from '../../data/TaskCell';
import { sort } from '../../data/DataUtil';
import { StateManager } from '../../stateManager/StateManager';

import gTexts from '../../resources/texts';

import Header from '../../components/layout/header'
import Footer from '../../components/layout/footer'
import gStyles from '../../styles/global.module.css';
import styles from './Search.module.css';

TaskCell.toString();    // suppress warning

/**
 * Search Page
 * - container component
 */

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 * @property {TaskCell[]} matchCells
 * @property {string} searchText
 * @property {string} lastUpdated
 */

/** @extends {React.Component<Props, State>} */
export default class Search extends React.Component {

  /** @param {Props} props */
  constructor(props) {
    super(props);
        /**@type {TaskCell[]} */ this.allCells = DataManager.getAll();
    sort(this.allCells);

    /**@type {State} */
    this.state = {
      matchCells: [],
      searchText: '',
      lastUpdated: '',
    };
  }

  componentDidMount() {
    // log ('Search did mount');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.lastUpdated !== StateManager.getData().tasks.lastUpdated) {
      this.setState({ lastUpdated: StateManager.getData().tasks.lastUpdated });
      this.allCells = DataManager.getAll();
      sort(this.allCells);
    }
  }

  render() {

    return (
      <div className={gStyles.pageBox}>
        <div className={gStyles.headerBox}>
          <Header title={gTexts.searchTaskTitle} currPage={page.SEARCH} showSearch={false} />
        </div>
        <div className={`${gStyles.bodyBox} `}>
          <div className={styles.searchBox}>
            <div className={styles.searchBar}>
              <SearchBar
                searchText={this.state.searchText}
                onInputChange={this.onInputChange}
                onClear={this.onClear}
              />

            </div>
            <div className={styles.searchResult}>
              {this.state.searchText ?
                <SearchResult
                  matchCells={this.state.matchCells}
                  searchText={this.state.searchText}
                  onSelect={this.onSelect}
                />
                :
                <SearchView
                  allCells={this.allCells}
                  onSelect={this.onSelect}
                />

              }

            </div>
          </div>

        </div>
        <div className={gStyles.footerBox}>
          <Footer />
        </div>

      </div>
    )

  }

  /**@param {string} id */
  onSelect = (id) => {
    PageManager.goToTaskPage(page.EDIT_TASK, page.SEARCH, id);
  }

  onClickBack = (e) => {
    goBack();
  }

  onClickCreate = (e) => {
    goToTaskPage(page.EDIT_TASK, page.SEARCH);
  }

  onClickHome = (e) => {
    goToPage(page.HOME);
  }

  /** @param {import('react').SyntheticEvent<HTMLInputElement>} e */
  onInputChange = (e) => {
    const searchText = e.currentTarget.value;
    const matchCells = DataManager.findCells(searchText);
    sort(matchCells);
    this.setState({ matchCells, searchText })
  }

  onClear = () => {
    this.setState({ searchText: '' })
  }

}

