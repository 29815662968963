import React from 'react';
import gTexts from '../../resources/texts';
import gStyles from '../../App.module.css';
import * as icon from '../../util/IconConstants';

import '../../resources/sprites.css';

import * as size from '../../util/SizeConstants';

// Edit Task Nav bar - must be stateless

/**
 * @typedef {Object} Props
 * @property { ()=>void} onClickBack
 */


/** @param {Props} props */
export const SearchNavbar = (props) => (
    <>
        <nav className={[gStyles.rowFlex, gStyles.navBar].join(' ')}>

            {/* back button */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                <button onClick={props.onClickBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
            </span>

            {/* title */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>{gTexts.searchImgTitle}</span>

        </nav>

    </>
);


