import React from 'react';

import * as TaskManager from '../../data/TaskManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'

import { TaskLevel } from '../../pages/viewByCategory/ViewData';
import * as TaskConstants from '../../util/TaskConstants'

import gStyles from '../../App.module.css';     // global styles
import styles from './TaskCard.module.css'
import '../../resources/sprites.css'
import '../../resources/sprites2.css'
import * as icon from '../../util/IconConstants'


/**
 * @param {Object} params
 * 
 * @param {TaskLevel} params.task
 */
const TaskCard = ({ task }) => {
  return (
    <div
      onClick={() => onSelect(task.taskId)}
      className={[gStyles.rowNowrapFlex, styles.taskLine].join(' ')}>
      {/* done */}
      <button
        onClick={(e) => onComplete(e, task.taskId)}
        className={[
          task.task.status === TaskConstants.Status.DONE ? icon.CHECKED_BOX : icon.UNCHECKED_BOX,
          gStyles.btnIcon].join(' ')}
        style={{ marginRight: '8px' }}
      >
      </button>

      {/* name */}
      <span className={[
        task.task.status === TaskConstants.Status.DONE ? styles.taskDone :
          task.task.status === TaskConstants.Status.IN_PROGRESS ? styles.taskInProgress :
            task.task.status === TaskConstants.Status.CANCELLED ? styles.taskCancelled :
              ''
      ].join(' ')}
      >
        {task.task.name}
      </span>

    </div>
  )

}

/**
 * on clicking the task name
 * @param {string} id 
 */
const onSelect = (id) => {
  PageManager.setTaskId(id);
  PageManager.goToPage(page.EDIT_TASK);
}

/**
 * on clicking the check button
 * @param {import('react').SyntheticEvent<HTMLButtonElement>} e
 * @param {string} id 
 */
const onComplete = (e, id) => {
  e.stopPropagation();
  const task = TaskManager.getCell(id);
  if (task.status === TaskConstants.Status.DONE) {
    task.status = TaskConstants.Status.NOT_STARTED;
  } else {
    task.status = TaskConstants.Status.DONE;
  }
  TaskManager.upsertCell(task);
}


export default TaskCard;