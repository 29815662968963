import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'

import {TaskCell} from '../data/TaskCell'
import { isEmpty} from './Utils';

dayjs.extend(weekOfYear);

TaskCell.toString() // suppress warning

export const addDays = (d, i, unit) => {
    return dayjs(d).add(i,unit);
}

export const toString = (d) => {
    return dayjs(d).format('YYYY-MM-DD');
}

/**
 * @param {TaskCell} task
 */
export const isBeforeThisWeek = (task) => {
    const startOfWeek = getStartOfWeek();
    if ( !isEmpty(task.startDate) && !isEmpty(task.endDate) ) {
        if ( dayjs(task.startDate).isBefore(startOfWeek) && dayjs(task.endDate).isBefore(startOfWeek) ) {
            return true;
        }
    } else {
        if ( !isEmpty(task.startDate) && dayjs(task.startDate).isBefore(startOfWeek) ) {
            return true;         
        }
        if ( !isEmpty(task.endDate) && dayjs(task.endDate).isBefore(startOfWeek) ) {
            return true;         
        }
    }
    return false;
}

/**
 * @param {TaskCell} task
 */
export const isTodayDate = (task) => {
    if ( !isEmpty(task.startDate) && isToday(task.startDate) ) {
        return true;
    } 
    if ( !isEmpty(task.endDate) && isToday(task.endDate) ) {
        return true;
    } 
    if ( !isEmpty(task.startDate) && !isEmpty(task.endDate) ) {
        // is today in range?
        if ( isDayInRange( dayjs(new Date()).toString(), task.startDate, task.endDate ) ) {
            return true;
        }
    }
    return false;
}

/**
 * @param {TaskCell} task
 */
export const isTomorrowDate = (task) => {
    if ( !isEmpty(task.startDate) && isTomorrow(task.startDate) ) {
        return true;
    } 
    if ( !isEmpty(task.endDate) && isTomorrow(task.endDate) ) {
        return true;
    } 
    if ( !isEmpty(task.startDate) && !isEmpty(task.endDate) ) {
        // is tomorrow in range?
        if ( isDayInRange( dayjs(new Date()).add(1,'day').toString(), task.startDate, task.endDate ) ) {
            return true;
        }
    }
    return false;
}

/**
 * @param {TaskCell} task
 */
export const isThisWeekDate = (task) => {
    if ( !isEmpty(task.startDate) && isThisWeek(task.startDate) ) {
        return true;
    } 
    if ( !isEmpty(task.endDate) && isThisWeek(task.endDate) ) {
        return true;
    } 
    if ( !isEmpty(task.startDate) && !isEmpty(task.endDate) ) {
        const startOfWeek = getStartOfWeek();
        const endOfWeek = dayjs(startOfWeek).add(6,'day');

        // check if startDate or EndDate is within this week
        if ( isDayInRange( task.startDate, startOfWeek.toString(), endOfWeek.toString() ) ) {
            return true;
        }
        if ( isDayInRange( task.endDate, startOfWeek.toString(), endOfWeek.toString() ) ) {
            return true;
        }

        // check if this week is between startDate and endDate
        if ( isDayInRange( startOfWeek.toString(), task.startDate, task.endDate ) ) {
            return true;
        }
        if ( isDayInRange( endOfWeek.toString(), task.startDate, task.endDate ) ) {
            return true;
        }
    }
    return false;
}

/**
 * @param {TaskCell} task
 */
export const isNextWeekDate = (task) => {
    if ( !isEmpty(task.startDate) && isNextWeek(task.startDate) ) {
        return true;
    } 
    if ( !isEmpty(task.endDate) && isNextWeek(task.endDate) ) {
        return true;
    } 
    if ( !isEmpty(task.startDate) && !isEmpty(task.endDate) ) {
        const startOfNextWeek = getStartOfWeek().add(1,'week');  // next week
        const endOfNextWeek = dayjs(startOfNextWeek).add(6,'day');

        // check if startDate or EndDate is within next week
        if ( isDayInRange( task.startDate, startOfNextWeek.toString(), endOfNextWeek.toString() ) ) {
            return true;
        }
        if ( isDayInRange( task.endDate, startOfNextWeek.toString(), endOfNextWeek.toString() ) ) {
            return true;
        }

        // check if next week is between startDate and endDate
        if ( isDayInRange( startOfNextWeek.toString(), task.startDate, task.endDate ) ) {
            return true;
        }
        if ( isDayInRange( endOfNextWeek.toString(), task.startDate, task.endDate ) ) {
            return true;
        }
    }
    return false;
}

/**
 * @param {TaskCell} task
 */
export const isAfterNextWeek = (task) => {
    const endOfNextWeek = getStartOfWeek().add(1, 'week').add(6, 'day');
    if (!isEmpty(task.startDate) && dayjs(task.startDate).isAfter(endOfNextWeek)) {
        return true;
    }
    if (!isEmpty(task.endDate) && dayjs(task.endDate).isAfter(endOfNextWeek)) {
        return true;
    }
    if (!isEmpty(task.startDate) && !isEmpty(task.endDate)) {
        if (dayjs(task.startDate).isAfter(endOfNextWeek) && dayjs(task.endDate).isAfter(endOfNextWeek)) {
            return true;
        }
    }
    return false;
}


/**@param {string} d */
export const isToday = (d) => {
    const dd = dayjs(d).startOf('day');  // no time
    const today = dayjs(new Date()).startOf('day');
    return dd.isSame(today);
}

/**@param {string} d */
const isTomorrow = (d) => {
    const dd = dayjs(d).startOf('day');  // no time
    const tomorrow = dayjs(new Date()).add(1,'day').startOf('day');
    return dd.isSame(tomorrow);
}

/**@param {string} d */
const isThisWeek = (d) => {
    const week1 = dayjs(d).week();
    const year1 = dayjs(d).year();
    const week2 = dayjs(new Date()).week();
    const year2 = dayjs(new Date()).year();
    const isThisWeek =  Math.abs(year1-year2) <=1 && week1 === week2;  // consider next year
    return isThisWeek;
}

/**@param {string} d */
const isNextWeek = (d) => {
    const week1 = dayjs(d).week();
    const year1 = dayjs(d).year();
    const nextWeek = dayjs(new Date()).add(1,'week');
    const week2 = nextWeek.week();  
    const year2 = nextWeek.year();
    const isNextWeek =  Math.abs(year1-year2) <=1 && week1 === week2;  // consider next year
    return isNextWeek;
}

/**
 * @param {string} d - the date to check
 * @param {string} d1
 * @param {string} d2  
 */
const isDayInRange = (d, d1, d2) => {
    const dd = dayjs(d).startOf('day');     // no time
    const dd1 = dayjs(d1).startOf('day');  
    const dd2 = dayjs(d2).startOf('day');  

    if (dd.isSame(dd1)) return true;
    if (dd.isSame(dd2)) return true;
    if (dd.isAfter(dd1) && dd.isBefore(dd2)) return true;
    if (dd.isAfter(dd2) && dd.isBefore(dd1)) return true;  // swap dates

    return false;
}

const getStartOfWeek = () => {
    return dayjs().startOf('week').startOf('day'); // start of week (depends on locale) without time
}

/**@param {string} d */
export const isWeekend = (d) => {
    const dayOfWeek = dayjs(d).day();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
        return true;
    }
    return false;
}

/**
 * @param {string} d1 
 * @param {string} d2
 */
export const isMatch = (d1, d2) => {
    const dd1 = dayjs(d1).startOf('day');  // no time
    const dd2 = dayjs(d2).startOf('day');
    return dd1.isSame(dd2);
}
