import * as DataStore from './TaskStore'
import {TaskCell} from './TaskCell'
import {isEmpty, Utils} from '../util/Utils'
import {StateManager} from '../stateManager/StateManager'
import * as Constant from '../util/TaskConstants';

import * as PersistenceManager from '../data/PersistenceManager'

TaskCell.toString(); // suppress warning

/** @param {TaskCell} cell */
export const upsertCell = (cell) => {
    try {
        if ( isEmpty(cell.taskId) ) {
            cell.taskId = generateId(cell);
        }
    
        DataStore.upsertCell(cell);
        PersistenceManager.saveAll();  // race condition? todo: try to pass as callback to reducer            
    } catch (error) {
        Utils.error('Error in TaskManager in upserting task: ', error.message);
        throw error;  // rethrow and let the caller handles it
    }

}

/**
 * 
 * @param {string} taskId 
 */
export const deleteCell = (taskId) => {
    try {
        DataStore.deleteCell(taskId);
        PersistenceManager.saveAll();            
    } catch (error) {
        Utils.error('Error in TaskManager in deleting task: ', error.message);
        throw error;  // rethrow and let the caller handles it        
    }
}

/**
 * 
 * @param {string} taskId 
 * @return {TaskCell}
 */
export const getCell = (taskId) => {
    /**@type {TaskCell[]} */
    const tasks = StateManager.getData().tasks.tasks;
    const taskCell = tasks.find( (t) => {
        return t.taskId === taskId
    })
    return taskCell;
}

/**@return {TaskCell[]} */
export const getAll = () => {
    return StateManager.getData().tasks.tasks;
}

/**
 * @param {string} categoryId
 * @return {TaskCell[]} 
 */
export const getChildren = (categoryId) => {
    const tasks = StateManager.getData().tasks.tasks;
    const children = tasks.filter( (t) => t.categoryId === categoryId);
    return children;
}

/**@param {TaskCell[]} tasks */
export const insertAll = (tasks) => {
    DataStore.insertAll(tasks);
}


/**
 * generate id based on user email and time
 * @param {TaskCell} cell 
 * @return {string}
 */
const generateId = (cell) => {
    const now = new Date().getTime();  // UTC

    // format: email_TASK_time
    const id = `${Constant.LOGIN_USER.USER_ID}${Constant.ID.LINK_CHAR}${Constant.ID.TASK_ID_PREFIX}${Constant.ID.LINK_CHAR}${now}`;

    return id;
}

/**
 * @param {string} searchText
 * @returns {TaskCell[]}
 */
export const findCells = (searchText) => {
    if (isEmpty(searchText)) return [];
    const allCells = getAll();
    const matchCells = allCells.filter( (c) => {
        return (
            c.name.toLowerCase().includes(searchText.toLowerCase()) ||  // .includes() return true for '' string !!!
            c.notes.toLowerCase().includes(searchText.toLowerCase())
        ) 
    });
    return matchCells;
}



