import React from 'react';

import * as LabelManager from '../../data/LabelManager'
import {TaskLabel} from '../../data/TaskLabel';

import { EditLabelNavbar } from './EditLabelNavbar'
import { EditLabelView, Section } from './EditLabelView';

import * as PageManager from '../../navigation/PageManager';
import texts from './LabelTexts'

import { isEmpty, logError, Utils } from '../../util/Utils'

import { State, Errors } from './LabelState'  // alias for the State type
import * as page from '../../util/PageConstants'
import { Notification, NotificationType } from '../../components/notification/Notification';

Errors.toString();    // suppress warning

/**
 * @typedef {Object} Props - create an alias for props
 */


/** @type {State} */
/** @extends {React.Component<Props, State>} */
export default class EditLabel extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        this.origTaskLabel = null;
        this.origStateFields = '';
        this.currLabelId = PageManager.getLabelId(); // to save passed in id to local variable so local update is synchronous
        this.prevParentId = '';     // to copy previous parent data to current
        this.selectedImageId = '';


        // initialize state
        /**@type {State} */
        this.state = this.initState();

    }

    /** @type {Errors}  */
    errors = { name: '', sameColor: '', title: '', content: '' };  // local object to track errors and save to state as needed

    
    componentDidMount() {
        // log ('Edit Label did mount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.selectedImageId !== PageManager.getImageId()) {
            this.selectedImageId = PageManager.getImageId();
            this.setState({fields: {...this.state.fields, image: this.selectedImageId}});
        }
    }

    render() {
        return (
            <>
                <EditLabelNavbar
                    currLabelId={this.currLabelId}
                    onClickBack={this.onClickBack}
                    onClickSave={this.onClickSave}
                    onClickSaveNew={this.onClickSaveNew}
                    onClickDelete={this.onClickDelete}
                />
                <EditLabelView
                    page={this.state}
                    onToggleSection={this.onToggleSection}
                    onInputChange={this.onInputChange}
                    onImageChange={this.onImageChange}
                    onClickAddImage={this.onClickAddImage}
                    onClickRemoveImage={this.onClickRemoveImage}
                    onSelectFontColor={this.onSelectFontColor}
                    onSelectBackgroundColor={this.onSelectBackgroundColor}
                />
            </>
        )
    }

    // ************* handle form events

    onClickBack = () => {
        // this.reset();
        PageManager.goBack();
    }

    onClickSave = () => {
        Utils.debug('saving...');
        this.validate();
        if ( this.hasError() ) {
            Notification.snackBarError(texts.validationError);
        } else {
            if ( this.hasChanged() ) {
                Notification.snackBarInfo(texts.save);
                this.save();    
            }
            PageManager.goBack();
        }        

    }

    onClickSaveNew = () => {
        this.validate();
        if ( this.hasError() ) {
            Notification.snackBarError(texts.validationError);
        } else {
            Notification.snackBarInfo(texts.saveNew);
            if ( this.hasChanged() ) {
                this.save();
            }
            this.reset(); // so it won't copy the passed in task label
            this.setState(this.initState());
        }
    }

    onClickDelete = () => {
        if (this.state.isNew) {
            PageManager.goBack();
        } else {
            Notification.dialog(
                texts.deleteWarning.title, 
                texts.deleteWarning.content,
                this.delete,  // pass callback when confirm delete
                NotificationType.WARN);
        }
    }

    /** @param {string} section */
    onToggleSection = (section) => {
        switch (section) {
            case Section.DESCRIPTION:
                this.setState({ expanded: { ...this.state.expanded, description: !this.state.expanded.description } });
                break;
            case Section.STYLING:
                this.setState({ expanded: { ...this.state.expanded, styling: !this.state.expanded.styling } });
                break;
            default:
                break;
        }
    }

    // ******* map form fields to state

    /** @param {import('react').SyntheticEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>} e */
    onInputChange = e => {
        const fields = Object.assign({}, this.state.fields);
        fields[e.currentTarget.name] = e.currentTarget.value;   // use currentTarget instead of target object
        this.setState({ fields }, this.postChange);    
    }

    onImageChange = e => {
      const fields = Object.assign({}, this.state.fields);
      const prevEmoji = fields[e.currentTarget.name];
      let emoji = e.currentTarget.value;
  
      if (prevEmoji !== emoji) {
        emoji = emoji.replace(prevEmoji, ""); // remove last emoji, only 1 emoji allowed
      }
      const allChars = /^[ -~]+$/;
      if (allChars.test(emoji)) return; // don't allow regular characters
  
      fields[e.currentTarget.name] = emoji;
      this.setState({ fields }, this.postChange);
    }
  
    postChange = () => {        
        // update errors as user updates fields
        if ( this.hasError() ) {
            this.validate();
        }
    }

    /** @param {string} code */
    onSelectFontColor = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, fontColor: false } })  // hide the dropdown onblur event
            return;
        }
        const changeCode = isEmpty(code) ? this.state.fields.fontColor : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, fontColor: !this.state.showDropdown.fontColor },
            fields: { ...this.state.fields, fontColor: changeCode },
        })
    }

    /** @param {string} code */
    onSelectBackgroundColor = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, bgColor: false } })  // hide the dropdown onblur event
            return;
        }
        const changeCode = isEmpty(code) ? this.state.fields.backgroundColor : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, bgColor: !this.state.showDropdown.bgColor },
            fields: { ...this.state.fields, backgroundColor: changeCode },
        })
    }

    onClickAddImage = () => {
        PageManager.goToPage(page.SEARCH_ICON, page.EDIT_LABEL);
    }


    onClickRemoveImage = () => {
        this.selectedImageId='';
        PageManager.clearImageId();
        this.setState({fields: {...this.state.fields, image:''}});
    }
    // ************* validations

    validate = () => {
        this.resetErrors()

        // empty name
        if ( isEmpty(this.state.fields.name) ) {
            this.errors.name = texts.emptyNameError;
        }
        // same color
        if (
            ( !isEmpty(this.state.fields.fontColor) && !isEmpty(this.state.fields.backgroundColor) ) &&
            ( this.state.fields.fontColor === this.state.fields.backgroundColor )
            ) {
            this.errors.sameColor = texts.sameColorError;
        }
        this.setState({ errors: this.errors });
    }

    resetErrors = () => {
        this.errors.name = '';
        this.errors.sameColor = '';
        this.errors.title = '';
        this.errors.content = '';
    }

    /** @returns {boolean} */
    hasError = () => {
        if (!isEmpty(this.errors.name)) return true;
        if (!isEmpty(this.errors.sameColor)) return true;
        if (!isEmpty(this.errors.title)) return true;
        if (!isEmpty(this.errors.content)) return true;
        return false;
    }


    // ******************************* save

    save = () => {
        Utils.debug('saving...')
        try {
            const label = this.stateToLabel(this.state);
            LabelManager.upsertLabel(label);
        } catch (error) {
            logError('Error saving label!', error);
        }

    }

    /**
     * copy state to label
     * @param {State} currState
     * @return {TaskLabel}
     */
    stateToLabel = (currState) => {
        /**@type {TaskLabel} */ let label = null;
        if (currState.isNew) {
            label = new TaskLabel();
        } else {
            label = this.origTaskLabel;
        }
        if (isEmpty(label)) {
            logError('Cannot copy state to label, label is empty!');
            return null;
        }
        label.labelId = currState.fields.labelId;
        label.name = currState.fields.name;
        label.image = currState.fields.image;
        label.backgroundColor = currState.fields.backgroundColor;
        label.fontColor = currState.fields.fontColor;
        return label;
    }

    // *************************** delete
    /**@param {string} ans */
    delete = (ans) => {
        if (Utils.isEmpty(ans)) return;
        if (ans.toLowerCase() !== 'yes') return;

        Notification.snackBarInfo(texts.delete);
        LabelManager.deleteLabel(this.state.fields.labelId);
        PageManager.goBack();
    }

    /**
     * @return {boolean}
     */
    hasChanged = () => {
        const currStateFields = JSON.stringify(this.state.fields);
        return (this.origStateFields !== currStateFields)
    }




    // ************************* initialization

    /** @return {TaskLabel} */
    getTaskLabel = () => {
        if (isEmpty(this.currLabelId)) return null;

        const taskLabel = LabelManager.getLabel(this.currLabelId);
        return taskLabel;
    }


    /** @return {State} */
    initState = () => {
        this.origTaskLabel = this.getTaskLabel();
        const baseState = new State();

        if ( !isEmpty(this.origTaskLabel) ) {
            baseState.isNew = false;
            this.labelToState(this.origTaskLabel,baseState);
        } else {
            baseState.isNew = true;
        }

        this.saveOrigState(baseState);

        return baseState;
    }

    reset = () => {
        PageManager.clearLabelId(); // clear the passed id
        this.currLabelId = ''; // clear the local id
        this.origTaskLabel = null;
        PageManager.setImageId('');
        this.selectedImageId = '';
    }

    /**@param {State} baseState */
    saveOrigState = (baseState) => {
        this.origStateFields = JSON.stringify(baseState.fields);  // save the state for diff later
    }

    /**
     * copy task label to state
     * @param {TaskLabel} label
     * @param {State} baseState
     */
    labelToState = (label, baseState)  => {
        if (isEmpty(label)) {
            logError('Cannot copy label to state, label is empty...');
        }
        baseState.fields.labelId     = label.labelId;
        baseState.fields.name       = label.name;
        baseState.fields.image     = label.image;
        baseState.fields.fontColor   = label.fontColor;
        baseState.fields.backgroundColor = label.backgroundColor;
    }


}