import React from 'react';

import { SetupLabelNavbar } from './SetupLabelNavbar';
import { SetupLabelView } from './SetupLabelView'

import { goToPage, goBack } from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import { log } from '../../util/Utils';
import { StateManager } from '../../stateManager/StateManager';
import * as LabelManager from '../../data/LabelManager'

import {sortLabels} from '../../util/ViewHelper'

import Header from '../../components/layout/header'
import Footer from '../../components/layout/footer'
import gStyles from '../../styles/global.module.css';
import texts from './LabelTexts';

/**
 * Setup Label Page
 * - container component
 */

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 * @property { import('../../data/TaskLabel')[]} State.allLabels
 * @property {string} lastUpdated
 * 
 */

/** @extends {React.Component<Props, State>} */
export default class SetupLabel extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        const labels = LabelManager.getAll();
        sortLabels(labels);
        /**@type {State} */
        this.state = {
            allLabels: labels,
            lastUpdated: '',
        };
    }

    componentDidMount() {
        // log ('Setup label did mount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.lastUpdated !== StateManager.getData().labels.lastUpdated) {
            const labels = LabelManager.getAll();
            sortLabels(labels);
            /**@type {State} */ const newState = {
                allLabels: labels,
                lastUpdated: StateManager.getData().labels.lastUpdated,
            }
            this.setState(newState);
        }
    }

    render() {
      return (
        <div className={gStyles.pageBox}>
          <div className={gStyles.headerBox}>
            <Header title={texts.setupLabelTitle} currPage={page.SETUP_LABEL} />
          </div>
          <div className={gStyles.bodyBox}>
              <SetupLabelView
                allLabels={this.state.allLabels}
                onSelect={this.onSelect}
              />
          </div>
          <div className={gStyles.footerBox}>
            <Footer onNew={this.onClickCreate} />
          </div>

        </div>
      )    
    }

    /**@param {string} id */
    onSelect = (id) => {
        PageManager.setLabelId(id);
        PageManager.clearImageId();
        PageManager.goToPage(page.EDIT_LABEL);
    }

    onClickBack = (e) => {
        goBack();
    }

    onClickCreate = (e) => {
        PageManager.clearLabelId();
        PageManager.clearImageId();
        PageManager.goToPage(page.EDIT_LABEL);
    }

    onClickHome = (e) => {
        goToPage(page.HOME);
    }


}

