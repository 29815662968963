import {TaskStoreState} from '../data/TaskStore'
import {CategoryStoreState} from '../data/CategoryStore';
import {LabelStoreState} from '../data/LabelStore';
import {PageStoreState} from '../navigation/PageStore'
import {MediaQueryStoreState} from '../navigation/MediaQueryStore'
import {NotificationStoreState} from './NotificationStore'
import { CalendarStoreState } from './CalendarStore';
import { SettingsStoreState } from './SettingsStore';

/**
 * The type of redux state based on all the composed reducers
 */
export class StateType {
    /**@type {TaskStoreState} */            tasks = new TaskStoreState();
    /**@type {CategoryStoreState} */        categories = new CategoryStoreState();
    /**@type {LabelStoreState} */           labels = new LabelStoreState();
    /**@type {PageStoreState} */            page = new PageStoreState();
    /**@type {MediaQueryStoreState} */      media = new MediaQueryStoreState();
    /**@type {NotificationStoreState} */    notification = new NotificationStoreState();
    /**@type {CalendarStoreState} */        calendar = new CalendarStoreState();
    /**@type {SettingsStoreState} */        settings = new SettingsStoreState();
}