

export class Fields {
    /**@type {string} */ categoryId = '';
    /**@type {string} */ status = '';
    /**@type {string} */ importance = '';
    /**@type {string} */ priority = '';
    /**@type {string} */ labelId = '';


}


export class Category {
    /**@type {string} */ id = '';
    /**@type {string} */ name = '';
}

export class Label {
    /**@type {string} */ id = '';
    /**@type {string} */ name = '';
}


export class ShowDropdown {
    /**@type {boolean} */ category = false;
    /**@type {boolean} */ status = false;
    /**@type {boolean} */ priority = false;
    /**@type {boolean} */ importance = false;
    /**@type {boolean} */ label = false;
}


/**
 * alias for the State object to use as type for the component
 */
export class State {
    /**@type {ShowDropdown}*/   showDropdown = new ShowDropdown();
    /**@type {Fields}*/         fields = new Fields();
    /**@type {Category[]}*/     categoryList = [];
    /**@type {Label[]}*/        labelList = [];
}

