import * as Constants from './TaskConstants';

export const isNotEmpty = (o) => {
    return !isEmpty(o);
}

export const isEmpty = (obj) => {
    
    const booleanValue = !!obj;
    if (booleanValue === true) {
        // truthy, perform other checks below
    } else {
        return true;  // falsy (7) - false, 0, 0n, '', null, undefined, NaN
    }

    // empty string
    if ((typeof obj === 'string' || obj instanceof String) && obj === '') {
        return true;
    }

    // empty array
    if (Array.isArray(obj) && !obj.length) {
        return true;
    }

    // empty object
    if (typeof obj === 'object' && obj.constructor === Object) {
        if (Object.keys(obj).length === 0) {
            return true;
        }
    }

    return false;


}

export const isEmptyOrig = (o) => {
    // falsy (7) - false, 0, 0n, '', null, undefined, NaN
    if (!o) return true;

    // empty string
    if ((typeof o === 'string' || o instanceof String) && o === '') {
        return true;
    }

    // empty array
    if (Array.isArray(o) && !o.length) {
        return true;
    }

    // empty object
    if (Object.keys(o).length === 0) {
        return true;
    }

    return false;
}

const separator = ' : ';

export function log() {
    if (Constants.ENVIRONMENT==='prod') return;  // disable in prod
    let s = [], a;
    for (a of arguments) { link(s, a); }
    console.log('^^^ ' + s.join(separator));
}

export function logDebug() {
    if (Constants.ENVIRONMENT==='prod') return;  // disable in prod
    let s = [], a;
    for (a of arguments) { link(s, a); }
    console.debug('--- ' + s.join(separator));
}

export function logWarn() {
    let s = [], a;
    for (a of arguments) { link(s, a); }
    console.warn('+++ ' + s.join(separator));
}

export function logError() {
    let s = [], a;
    for (a of arguments) { link(s, a); }
    console.error('*** ' + s.join(separator));
}

function link(s, a) {
    try {
        if (typeof a === 'object') {
            s.push(JSON.stringify(a));
        } else {
            s.push(a);
        }            
    } catch (error) {
        console.error('!!! Cant build array of arguments : ', error);
    }
}

export class Utils {
    static isEmpty =(o) => { return isEmpty(o)}
    static isNotEmpty =(o) => { return isNotEmpty(o)}
    static log = (...args) => {log(...args)}
    static logDebug = (...args) => {logDebug(...args)}
    static debug = (...args) => {logDebug(...args)}
    static logWarn = (...args) => {logWarn(...args)}
    static warn = (...args) => {logWarn(...args)}
    static logError = (...args) => {logError(...args)}
    static error = (...args) => {logError(...args)}
}

