import React, { useState, useEffect } from 'react';

import TaskCard from '../taskCard/TaskCard';
import { CategoryLevel } from '../../pages/viewByCategory/ViewData';

import { isEmpty } from '../../util/Utils';

import '../../resources/sprites.css'
import '../../resources/sprites2.css'
import * as icon from '../../util/IconConstants'

import gStyles from '../../App.module.css';     // global styles
import styles from './CategoryCard.module.css'

/**
 * 
 * @param {Object} params
 * @param {CategoryLevel} params.category
 * 
 */
const CategoryCard = ({ category }) => {
  const [isExpand, setExpand] = useState(true); // local toggle

  useEffect(() => {
    setExpand(category.isExpanded); // expand/collapse all
  }, [category.isExpanded])

  const isShowBody = (category) => {
    if (isExpand) {
      const tasks = Array.from(category.taskMap.values());
      return tasks.length;
    }
    return false;
  }


  return (
    <div className={[styles.categorySection].join(' ')} >
      <div
        onClick={() => setExpand(!isExpand)}
        className={[gStyles.rowNowrapFlex, styles.categoryHeader].join(' ')}
        style={{ backgroundColor: category.category.backgroundColor, color: category.category.fontColor }}
      >
        {/* left */}
        <div className={styles.left}>
          {/* image */}
          {!isEmpty(category.category.image) &&
            <span className={gStyles.btnImg} style={{ marginRight: '5px' }}>{category.category.image}</span>
          }

          {/* name */}
          <span>{category.displayText}</span>
        </div>

        {/* right */}
        <div>

          {/* expand */}
          <button className={[
            isExpand ? icon.COLLAPSE : icon.EXPAND,
            gStyles.btnIcon,
          ].join(' ')}></button>
        </div>

      </div>
      <div className={styles.categoryBody} style={{ display: isShowBody(category) ? '' : 'none' }}>
        {buildTask(category)}
      </div>

    </div>
  )
}

/**
 * build task level component
 * @param {CategoryLevel} category
 * 
 */
const buildTask = (category) => {
  const tasks = Array.from(category.taskMap.values());
  const taskView = tasks.map((t, i) => (
    <TaskCard key={i} task={t} />
  ))
  return taskView;
}

export default CategoryCard;