/**
 * hack to import raw html into js
 * 
 * Tip: copy html code into .html file for easy editing and paste back here
 */

export const rawHtml = `

<style>
  .section {
    line-height: 22px;
    font-size: 14px;
  }

  .productName {
    font-weight: 500;
    color: darkslateblue;
  }

  .head1 {
    line-height: 28px;
    font-size: 20px;
  }

  .list {
    list-style-type: none;
  }

  .list li {
    padding-bottom: 4px;
  }

  .emphasis {
    font-style: italic;
  }
  .spacer {
    margin-top: 16px;
  }
</style>

<div class="section">
  <p>
    <span class="productName">MyBrainMate</span> is a Personal Information Management (PIM) software that helps you
    become productive personally.
  </p>
  <p>
    Personal information is
  </p>
  <ul class="list">
    <li>About “you”</li>
    <li>Owned by “you”</li>
    <li>Relevant to “you”</li>
    <li>Experienced by “you”</li>
    <li>Used and consumed by “you”</li>
  </ul>
  <p>
    The purpose of <span class="productName">MyBrainMate</span> is to facilitate the acquiring, recording, organizing,
    usage, and management of personal information.
  </p>
  <p>
    <span class="productName">MyBrainMate</span> helps you manage precious resources such as time, energy, money, and
    information to become more efficient in utilizing and essentially enjoying these resources.
  </p>
  <p class="head1">
    How it works
  </p>
  <p>
    Generally, we can say that there are two stages on how our brain functions.
  </p>
  <p>
    First stage, is the process of collecting, documenting, and organizing data. This is the <span
      class="emphasis">coding</span> part.
  </p>
  <p>
    The second stage is the analysis, evaluation of data, and using them for decision-making. This is the <span
    class="emphasis">thinking</span> part.
  </p>
  <p>
    <span class="productName">MyBrainMate</span> can help you on the coding part so that you can focus on the thinking
    part.
  </p>
  <p class="spacer">
    “Let <span class="productName">MyBrainMate</span> do the heavylifting, Let your brain do the thinking!”
  </p>
</div>

`
