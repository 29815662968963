export default  {
  homeCaption: "Let's get productive!",
    
    createHeader:   'Create',
    searchHeader:   'Search',
    viewHeader:     'Views',
    appsHeader:     'Switch App',
    setupHeader:    'Setup',

    startHeader:     'Getting started...',
    infoHeader:     'Learn more...',

    iWantTo:        '',
    iWantTo2:        'I want to',
    createTask:     'Create Task',
    viewTask:     'View Tasks',

    searchTask:     'Search My Task',
    iWantToView:    '',
    iWantToView2:    'I want to view my task',
    viewTemplate:     'View Template',
    byCategory:     'View by Category',
    byHotTask:      'View by Hot Tasks',
    byDate:         'View by Date',
    byPriority:     'View by Priority',
    byImportance:   'View by Importance',
    byStatus:       'View by Status',
    byLabel:        'View by Label',
    gotoApps:       'Go to Apps list',
    iWantToSetup:   '',
    iWantToSetup2:   'I want to setup',
    taskCategories: 'Task Categories',
    taskLabels:     'Task Labels',
    systemSettings: 'System Settings',
    iWantToKnow:    '',
    iWantToKnow2:    'I want to know',
    about:          'About MyBrainMate',
    howToUse:       'How to use MyBrainMate Task',
    tipsTricks:     'Tips & Tricks of MyBrainMate Task',

    imgTodo:        'Todo List',
    imgCategory:    'Categorize',
    imgSchedule:    'Schedule',
    imgSort:        'Arrange',
    imgSearch:      'Search',

    copyRight: '©Copyright 2022 ',
    company: 'LunareSoft'
}