import React from 'react'
import styles from './Calendar.module.css'
import gStyles from '../../App.module.css'
import {Utils} from '../../util/Utils'
import dayjs, { Dayjs } from 'dayjs'
import * as DateUtil from '../../util/DateUtil'
import texts from './CalendarTexts'
import { SelectFit } from '../dropDown/Dropdown'
import { SelectOption } from '../dropDown/SelectProps'
import * as TaskConstants from '../../util/TaskConstants'
 import * as icon from '../../util/IconConstants'
import * as CalendarStore from '../../stateManager/CalendarStore'
import { StateManager } from '../../stateManager/StateManager'

Dayjs ? Dayjs.toString() : (()=>('')).toString();  // suppress warning

/**
 * store the handler and execute when calendar closes
 * @param {string} selectedDate 
 */
let onSelectHandler = (selectedDate) => { };  // declared outside so it can be access by the static function

class CalendarState {
    /**@type {string} */ inputDate = CalendarStore.getCalendarDate();
    /**@type {boolean} */ isShowYear = false;
    /**@type {boolean} */ isShowMonth = false;          
    /**@type {Dayjs} */ currentDate = null;
    /**@type {Dayjs[][]} */ weeks = [];
    /**@type {number} */currMonth = 0;
    /**@type {SelectOption[]} */ months = [];
    /**@type {SelectOption[]} */years = []; 
}

export class Calendar extends React.Component {

    constructor(props) {
        super(props);

        this.state = new CalendarState();

        this.state = this.initCalendar();

        this.calendarRef = React.createRef(); // reference to calendar component to capture click outside

        this.monthRef = React.createRef(); // reference to month table of the calendar to capture swipe left/right

    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside, false); 

        swipedetect(this.monthRef.current, this.swipeHandler)
    }

    /**
     * next/previous month when swiping left or right
     * 
     * @param {string} dir */
    swipeHandler = (dir) => {
        if (dir === 'right') {
            this.onNext();
        } else if (dir === 'left') {
            this.onPrevious();
        }

    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside, false); 
    }

    onClickOutside = (e) => {
        if (!this.calendarRef.current.contains(e.target)) {  
            // click outside of calendar, close
            this.onClose('');
        }
    }

    /**
     * display calendar
     * 
     * @param {string} inputDate
     * @param {(d:string)=>void} handler
     * @param {string} [targetField]
     */
    static showCalendar (inputDate, handler, targetField='')  {
        if (Utils.isEmpty(inputDate)) inputDate = new Date().toString();
        // set the date to show calendar
        CalendarStore.setCalendarDate(inputDate, targetField);

        onSelectHandler = handler;  // save the handler for later execution

    }

    /**
     * check if fieldname is the current target of the calendar
     * @param {string} fieldName */
    static isShowCalendar(fieldName) {
        return Utils.isNotEmpty(CalendarStore.getCalendarDate()) && CalendarStore.getTargetField() === fieldName;
    }

    /**@param {string} selectedDate */
    onClose = (selectedDate) => {
        CalendarStore.clearCalendar();  // this will hide the calendar
        if (dayjs(selectedDate).isValid()) {
            onSelectHandler(selectedDate);  // execute the handler and pass the selected date
        }
    }

    /**@param {string} inputYear */
    onSelectYear = (inputYear) => {
        if (inputYear.toLowerCase() === 'onblur') {
            this.setState({isShowYear: false});
            return;
        }
        if (Utils.isEmpty(inputYear)) {
            this.setState({isShowYear: !this.state.isShowYear});   // toggle dropdown
            return;
        }
        const currDate = dayjs(CalendarStore.getCalendarDate());
        const currYear = parseInt(inputYear); // replace the year
        const currMonth = currDate.month();
        const currDay = currDate.date();
        const newDate = `${currYear}-${currMonth}-${currDay}`;
        Utils.logDebug('onSelectYear, newDate', newDate);

        CalendarStore.setCalendarDate(newDate, CalendarStore.getTargetField());
        this.setState({isShowYear: false});
    }

    /**@param {string} inputMonth */
    onSelectMonth = (inputMonth) => {
        if (inputMonth.toLowerCase() === 'onblur') {
            this.setState({isShowMonth: false});
            return;
        }
        if (Utils.isEmpty(inputMonth)) {
            this.setState({isShowMonth: !this.state.isShowMonth});   // toggle dropdown
            return;
        }
        const currDate = dayjs(CalendarStore.getCalendarDate());
        const currYear = currDate.year();
        const currMonth = parseInt(inputMonth) + 1;  // replace the month
        const currDay = currDate.date();
        const newDate = `${currYear}-${currMonth}-${currDay}`;
        Utils.logDebug('onSelectMonth, newDate', newDate);

        CalendarStore.setCalendarDate(newDate, CalendarStore.getTargetField());
        this.setState({isShowMonth: false});
    }

    onNext = () => {
        const currDate = dayjs(CalendarStore.getCalendarDate());
        const newDate = currDate.add(1, 'month');
        CalendarStore.setCalendarDate(newDate.format('YYYY-MM-DD'), CalendarStore.getTargetField());
    }
    
    onPrevious = () => {
        const currDate = dayjs(CalendarStore.getCalendarDate());
        const newDate = currDate.subtract(1, 'month');
        CalendarStore.setCalendarDate(newDate.format('YYYY-MM-DD'), CalendarStore.getTargetField());
    }
    
    componentDidUpdate(prevProps, prevState) {
        if ( Utils.isNotEmpty(StateManager.getData().calendar.inputDate) &&
            this.state.inputDate !== StateManager.getData().calendar.inputDate) {
            this.setState({
                inputDate: StateManager.getData().calendar.inputDate,
                }, this.resetState);
        }
    }

    resetState = () => {
        this.setState(this.initCalendar());
    }

    render() {

        return (
            <div className={styles.calendar} ref={this.calendarRef}>
                {/* navigation header */}
                <div className={gStyles.colFlex}>
                    <div className={styles.close} onClick={() => this.onClose('')}>
                        <span className={gStyles.splitRight}>&times;</span>
                    </div>
                    <div className={[gStyles.rowNowrapFlex, styles.dateNavigator].join(' ')}>

                        <span onClick={this.onPrevious}
                            className={[icon.PREVIOUS, gStyles.btnIcon].join(' ')}
                            style={{ marginLeft: '10px' }}
                        ></span>

                        <div className={[gStyles.rowNowrapFlex, styles.quickSelector].join(' ')}>
                            {/* Month */}
                            <div style={{ marginLeft: '20px' }}>
                                <SelectFit
                                    selectedOption={new SelectOption(this.state.currentDate.month() + '', this.state.currentDate.format('MMMM'))}
                                    selectOptions={this.state.months}
                                    isShow={this.state.isShowMonth}
                                    onSelect={this.onSelectMonth}
                                />
                            </div>

                            {/* Year */}
                            <div style={{ marginLeft: '20px' }}>
                                <SelectFit
                                    selectedOption={new SelectOption(this.state.currentDate.year() + '', this.state.currentDate.format('YYYY'))}
                                    selectOptions={this.state.years}
                                    isShow={this.state.isShowYear}
                                    onSelect={this.onSelectYear}
                                />
                            </div>

                        </div>

                        <span onClick={this.onNext}
                            className={[gStyles.splitRight, icon.NEXT, gStyles.btnIcon].join(' ')}
                            style={{ marginRight: '10px' }}
                        ></span>

                    </div>
                </div>
    
                {/* month table */}
                <div className={styles.calMonth}>
                    <table className={styles.calTable}>
                        {/* days header */}
                        <thead>
                             {/* classes, no effect on tr ? */}
                            <tr>
                                <th className={styles.calHeaderDay}>{texts.mon}</th>
                                <th className={styles.calHeaderDay}>{texts.tue}</th>
                                <th className={styles.calHeaderDay}>{texts.wed}</th>
                                <th className={styles.calHeaderDay}>{texts.thu}</th>
                                <th className={styles.calHeaderDay}>{texts.fri}</th>
                                <th className={[styles.calHeaderDay, styles.calWeekend].join(' ')}>{texts.sat}</th>
                                <th className={[styles.calHeaderDay, styles.calWeekend].join(' ')}>{texts.sun}</th>
                            </tr>
    
                        </thead>

                        {/* days per week */}
                        {/* set reference for use in swiping left or right */}
                        <tbody ref = {this.monthRef}>
                            {this.state.weeks.map((w, i) => (
                                <tr key={i}>
                                    {w.map((/**@type {Dayjs} */d, j) => (
                                        <td key={j}
                                            className={[
                                                d.month() === this.state.currMonth ? styles.calDay : styles.calGray,
                                                DateUtil.isWeekend(d.toString()) ? styles.calWeekend : '',
                                                DateUtil.isMatch(d.toString(), this.state.currentDate.toString()) ? styles.calMatch : '',
                                            ].join(' ')}
                                            onClick={()=>this.onClose(d.toString())}
                                        >{d.date()}</td>
                                    ))}
                                </tr>
                            ))}
    
                        </tbody>
    
                    </table>
                </div>
    
            </div>
        )
    }

    /**@returns {CalendarState} */
    initCalendar = () => {
        Utils.logDebug('initializing calendar');

        const baseState = {...this.state};

        const currentDate = dayjs(this.state.inputDate);

        /**@type {Dayjs[][]} */
        const weeks = buildCalendar(this.state.inputDate);
    
        const currMonth = currentDate.month();
        const months = buildMonths();
        const years = buildYears();
        
        baseState.currentDate = currentDate;
        baseState.currMonth = currMonth;
        baseState.weeks = weeks;
        baseState.months = months;
        baseState.years = years;

        return baseState;
        
    }

}

// ************************* helper functions

/**@returns {SelectOption[]} */
const buildYears = () => {
    const currYear = dayjs(new Date()).year();
    const startYear = currYear - TaskConstants.DateRange.ADD_YEARS;  // 10 years from the past
    const endYear = currYear + TaskConstants.DateRange.ADD_YEARS;   // 10 years to the future

    const maxYears = endYear - startYear;

    /**@type {SelectOption[]} */
    let years = [];

    for (let i=0; i<=maxYears; i++) {
        const thisYear = startYear + i;
        years[i] = new SelectOption(thisYear+'', thisYear+'');
    }
    return years;
}


/**@returns {SelectOption[]} */
const buildMonths = () => {
    const startOfYear = dayjs(new Date()).startOf('year');

    /**@type {SelectOption[]} */
    let months = [];

    for (let i=0; i<12; i++) {
        const thisMonth = startOfYear.add(i,'month');
        months[i] = new SelectOption(thisMonth.month()+'', thisMonth.format('MMMM'));
    }
    return months;
}

/**
 * @param {string} d 
 * @returns {Dayjs[][]}
 */
const buildCalendar = (d) => {
    
    const dd = dayjs(d);

    const daysInMonth =  dd.daysInMonth();
    const dayBuckets = [
        [], // Mon
        [], // Tue
        [], // Wed
        [], // Thu
        [], // Fri
        [], // Sat
        [], // Sun
    ];
    // put dates from previous month
    const startOfMonth = dd.startOf('month');
    const startDayOfMonth = getDay(startOfMonth); // startOfMonth.day();  
    for (let i=0; i<startDayOfMonth; i++) {
        dayBuckets[i].push(startOfMonth.subtract(startDayOfMonth-i, 'day'));
    }
    // put each day of month to its corresponding day bucket
    for (let i=0; i<daysInMonth; i++) {
        const daily = startOfMonth.add(i, 'day');
        dayBuckets[getDay(daily)].push(daily);  // Sun=0, Mon=1, ...
    }
    // put dates to next month
    const endOfMonth = dd.endOf('month');
    const endDayOfMonth = getDay(endOfMonth); // endOfMonth.day();  
    for (let i=endDayOfMonth+1; i<7; i++) {
        dayBuckets[i].push(endOfMonth.add(i-endDayOfMonth, 'day'));
    }
    
    const noWeeks = dayBuckets[0].length; // just get the first day of week, should be the same for all days
    const noDays = 7; // Mon to Sun

    // build each week
    const weeks = [];
    for (let weekIdx=0; weekIdx<noWeeks; weekIdx++) {
        let days = [];
        for (let dayIdx=0; dayIdx<noDays; dayIdx++) {
            days[dayIdx] = dayBuckets[dayIdx][weekIdx];
        }
        weeks[weekIdx] = days;
    }
    // Utils.logDebug('weeks', weeks);
    return weeks;
}


/**
 * force start of week to start on Monday
 * todo: read from config the start of week
 * 
 * @param {Dayjs} d 
 */
const getDay = (d) => {
    if (d.day()===0) return 6;
    return d.day() - 1;
}


// ********************* swiping

/**
 * adapted from http://javascriptkit.com/javatutors/touchevents2.shtml
 * @param {*} el 
 * @param {*} callback 
 */
function swipedetect(el, callback){
  
    var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 100, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 300, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function(swipedir){}
  
    touchsurface.addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        swipedir = 'none'
        distX = 0
        startX = touchobj.pageX
        startY = touchobj.pageY
        startTime = new Date().getTime() // record time when finger first makes contact with surface
        // e.preventDefault()  // let the event bubble so user can select date by touching
    }, false)
  
    touchsurface.addEventListener('touchmove', function(e){
        e.preventDefault() // prevent scrolling when inside DIV
    }, false)
  
    touchsurface.addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
        distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
        elapsedTime = new Date().getTime() - startTime // get time elapsed
        if (elapsedTime <= allowedTime){ // first condition for awipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                swipedir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                swipedir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
            }
        }
        handleswipe(swipedir)
        // e.preventDefault()  // let the event bubble so user can select date by touching
    }, false)
}


