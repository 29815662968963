export default {

    setupLabelTitle     : 'Setup Labels',
    editLabelTitle      : 'Edit Label',
    newLabelTitle       : 'New Label',

    descriptionHeader   : 'Description',
    stylingHeader       : 'Styling',

    nameLabel           : 'Name:',
    nameHint            : 'Enter label name',
    parentLabel         : 'Parent:',
    iconLabel           : 'Icon:',
    iconHint            : 'Use your keyboard to select emoji',
    textColorLabel      : 'Text color:',
    bgroundLabel        : 'Background color:',
    previewLabel        : 'Preview:',

    none                : '(None)',


    // help

    // error messages
    emptyNameError      : 'You forgot to input label name, duh!',
    rootNameError       : `ROOT category name is reserved, c'mon be more creative!`,
    emptyParentError    : 'Hey, you forgot to choose parent category!',
    sameColorError      : 'Duh, can\'t set same color for text and background',

    deleteWarning       : {title:'Delete Label',content:'Oh oh! This will remove the label from tasks where it was tagged. Would you like to continue?'},

    // popup messages
    validationError     : 'Uh oh, errors found!',
    save                : 'Saving label...',
    saveNew             : 'Saving & new...',
    delete              : 'Deleting label...',

    noLabels            : 'There are no labels. You can use labels to tag your tasks, start creating by tapping the + button...'

}