import React from 'react';

import { SetupCategoryNavbar } from './SetupCategoryNavbar';
import { SetupCategoryView } from './SetupCategoryView'

import { goToPage, goBack } from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import * as DataUtil from '../../data/DataUtil';
import { StateManager } from '../../stateManager/StateManager';

import gTexts from '../../resources/texts';
import Header from '../../components/layout/header'
import Footer from '../../components/layout/footer'
import gStyles from '../../styles/global.module.css';

/**
 * Setup Category Page
 * - container component
 */

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 * @property { import('../../data/TaskCategory')[]} State.allCategories
 * @property {string} lastUpdated
 * 
 */

/** @extends {React.Component<Props, State>} */
export default class SetupCategory extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        /**@type {State} */
        this.state = {
            allCategories: DataUtil.getCategoriesHierarchy(),
            lastUpdated: '',
        };
    }

    componentDidMount() {
        // log ('Setup category did mount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.lastUpdated !== StateManager.getData().categories.lastUpdated) {
            /**@type {State} */ const newState = {
                allCategories: DataUtil.getCategoriesHierarchy(),
                lastUpdated: StateManager.getData().categories.lastUpdated,
            }
            this.setState(newState);
        }
    }

    render() {
        // log ('Setup category, render...');
      return (
        <div className={gStyles.pageBox}>
          <div className={gStyles.headerBox}>
            <Header title={gTexts.setupCategoryTitle} currPage={page.SETUP_CATEGORY} />
          </div>
          <div className={gStyles.bodyBox}>
              <SetupCategoryView
                allCategories={this.state.allCategories}
                onSelect={this.onSelect}
              />
          </div>
          <div className={gStyles.footerBox}>
            <Footer onNew={this.onClickCreate} />
          </div>

        </div>
      )
    }

    /**@param {string} id */
    onSelect = (id) => {
        PageManager.setCategoryId(id);
        PageManager.clearImageId();
        PageManager.goToPage(page.EDIT_CATEGORY);
    }

    onClickBack = (e) => {
        goBack();
    }

    onClickCreate = (e) => {
        PageManager.clearCategoryId();
        PageManager.clearImageId();
        PageManager.goToCategoryPage(page.EDIT_CATEGORY);
    }

    onClickHome = (e) => {
        goToPage(page.HOME);
    }


}

