import React, { useState } from 'react'

import '../../resources/sprites2.css'
import * as page from '../../util/PageConstants'
import * as icon from '../../util/IconConstants'
import Menu from '../menu/Menu';

import layout from './layout.module.css';
// import gStyles from '../../styles/global.module.css';
import gStyles from '../../App.module.css';
import { goToPage } from '../../navigation/PageManager'


function Header({ title, showSearch = true, currPage='' }) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className={layout.header}>

      {/* left */}
      <div className={layout.headerLeft}>
        <button className={[gStyles.btnIcon, icon.MAIN_MENU,].join(' ')} onClick={() => setShowMenu(true)}></button>
        <span className={layout.titleText}>{title}</span>
      </div>

      {showMenu && <Menu currPage={currPage} onClose={() => setShowMenu(false)} />}

      {/* right */}
      {showSearch &&
        <div className={layout.headerRight}>
          <button className={[gStyles.btnIcon, icon.SEARCH,].join(' ')} onClick={() => goToPage(page.SEARCH)}></button>
        </div>
      }

    </div>
  )
}

export default Header;