export default {

    byCategory      : 'View By Category',
    byHotTask       : 'View By Hot Tasks',
    byDate          : 'View By Date',
    byPriority      : 'View By Priority',
    byImportance    : 'View By Importance',
    byStatus        : 'View By Status',
    byLabel         : 'View By Label',

}
