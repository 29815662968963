import React, {useEffect, useRef} from 'react'
import styles from './Menu.module.css'
import dStyles from '../dropDown/Dropdown.module.css'
import gStyles from '../../App.module.css'
import { Utils } from '../../util/Utils'
import texts from './MenuTexts'
import { SelectOption } from '../dropDown/SelectProps'
import { goToPage } from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import * as icon from '../../util/IconConstants'


/**
 * @typedef {Object} Props
 * @property {string} currPage
 * @property {()=>void} onClose
 * 
 * @typedef {Object} State
 */
function Menu({currPage, onClose}) {

  const home = [
    {code:page.HOME, description:texts.home},
  ];

  const allViews = [
    {code:page.VIEW_BY_CATEGORY, description:texts.byCategory, image:icon.MENU_BY_CATEGORY},
    {code:page.VIEW_BY_HOT_TASKS, description:texts.byHotTask, image:icon.MENU_BY_HOT_TASKS},
    {code:page.VIEW_BY_DATE, description:texts.byDate, image:icon.MENU_BY_DATE},
    {code:page.VIEW_BY_PRIORITY, description:texts.byPriority, image:icon.MENU_BY_PRIORITY},
    // {code:page.VIEW_BY_IMPORTANCE, description:texts.byImportance, image:icon.MENU_BY_IMPORTANCE},
    {code:page.VIEW_BY_STATUS, description:texts.byStatus, image:icon.MENU_BY_STATUS},
    {code:page.VIEW_BY_LABEL, description:texts.byLabel, image:icon.MENU_BY_LABEL},
  ];

  const settings = [
    {code:page.SETUP_CATEGORY, description:texts.setupCategories},
    {code:page.SETUP_LABEL, description:texts.setupLabels},
    {code:page.SYSTEM, description:texts.settings},
  ];

  const info = [
    {code:page.ABOUT, description:texts.about},
    {code:page.HOW_TO, description:texts.howTo},
    {code:page.TIPS_TRICKS, description:texts.tips},
  ]

  const switchRef = useRef();

  // on mount
  useEffect(() => {
      document.addEventListener("mousedown", onClickOutside, false);

      return () => {
        document.removeEventListener("mousedown", onClickOutside, false);
      };
  }, []);

  const onClickOutside = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!switchRef.current.contains(e.target)) {
        onSwitch();
    }
}

/**@param {string} [page] */
const onSwitch = (page = '') => {
    onClose();

    if (Utils.isEmpty(page)) return;
    if (page === currPage) return;

    goToPage(page);

}

const buildOptions = (options) => (
  options.map((option) => (
    <div key={option.code} 
      onClick={() => onSwitch(option.code)}
      className={[styles.selectOption, currPage === option.code ? styles.selectOptionSelected : '',].join(' ')}>
        {/* <button className={`${gStyles.btnIcon} ${option.image}`}></button> */}
      {option.description}
    </div>
  ))
);

  return (
    <div ref={switchRef}>
      <div className={[gStyles.colFlex, styles.menu].join(' ')}>

        {/* home */}
        {buildOptions(home)}

        {/* views */}
        <div className={styles.divider}>{'Views'}</div>
        {buildOptions(allViews)}

        <div className={styles.divider}>{'Settings'}</div>
        {/* settings */}
        {buildOptions(settings)}



      </div>
    </div>
  )
}

export default Menu;
